import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, useStyles, } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../../saga/saga-context";
import container from "./style";
import { useEffect, useState } from "react";
import { useSubmit } from "../../../saga/use-submit";
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import '../index.css';
import { localDatetime } from "../../../utils";
import { OrderStatusView } from "../status-view";
import { useStream } from "../../../saga/use-stream";
import { SearchToolBar } from "../../../components/Search";
import { StreamNav } from "../../../components/StreamNav";
import { useProgressBar } from "../../../components/ProgressBar";
export const filters = [
    { title: "Order Id", value: "order_id", filter: false },
    { title: "Buyer First Name", value: "first_name", filter: false },
    { title: "Buyer Last Name", value: "last_name", filter: false },
];
const ConfigInternal = (props) => {
    const { routerProps, shipping_type } = props;
    const { submit, error } = useSubmit();
    const { page: pageStr } = routerProps.match.params;
    const page = parseInt(`${pageStr}`);
    const queryParams = { page_size: 10 };
    const { status, stream, isFetching, data: orders, loadPage, loadNextPage, loadUrl } = useStream("/trendutube/v1/admin-order-processing/orders/details", { page, queryParams });
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const columns = [
        {
            field: 'order_date', headerName: 'Date', flex: 0.2, headerClassName: 'custom-header', renderCell: (params) => (_jsx(_Fragment, { children: localDatetime(params.row.order_date) }, void 0))
        },
        {
            field: 'order_id', headerName: 'Order Id', flex: 0.1, headerClassName: 'custom-header',
            renderCell: (params) => (_jsxs(NavLink, Object.assign({ to: `/dashboard/orders/order-full-info/${params.row.id}` }, { children: [" ", params.row.order_id, " "] }), void 0)),
        },
        {
            field: 'status', headerName: 'Status', flex: 0.2, headerClassName: 'custom-header', renderCell: (params) => (OrderStatusView(params.row.status))
        },
        { field: 'payment_mode', headerName: 'Payment Mode', flex: 0.1, headerClassName: 'custom-header' },
        { field: 'net_amount', headerName: 'Net Amount', flex: 0.1, headerClassName: 'custom-header' },
        {
            field: 'buyer_info', headerName: 'Buyer Info', flex: 0.1, headerClassName: 'custom-header',
            renderCell: (params) => {
                var _a, _b, _c, _d;
                return (_jsx("div", { children: _jsx("div", { children: _jsx("b", { children: `${((_b = (_a = params.row.buyer) === null || _a === void 0 ? void 0 : _a.personal_profile) === null || _b === void 0 ? void 0 : _b.first_name) || ""} ${((_d = (_c = params.row.buyer) === null || _c === void 0 ? void 0 : _c.personal_profile) === null || _d === void 0 ? void 0 : _d.last_name) || ""}` }, void 0) }, void 0) }, void 0));
            },
        },
    ];
    const [maxPage, setMaxPage] = useState(page);
    const setLoading = useProgressBar();
    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (stream && (stream.page > maxPage))
            setMaxPage(stream.page);
    }, [stream]);
    const { productstyle, bigtable } = useStyles("productstyle", "bigtable");
    function onSearchChange(name, filters, data12, from_date, to_date) {
        const qp = { name };
        if (from_date && to_date) {
            qp["from_date"] = from_date;
            qp["to_date"] = to_date;
        }
        else {
            delete qp["from_date"];
            delete qp["to_date"];
        }
        if (name && name.length >= 2 || from_date && to_date) {
            if (!name) {
                delete qp["name"];
            }
            if (name == "date") {
                if (from_date && to_date) {
                    qp["from_date"] = from_date;
                    qp["to_date"] = to_date;
                }
                else {
                    delete qp["from_date"];
                    delete qp["to_date"];
                }
                delete qp["name"];
            }
            else {
                filters.forEach(filter => {
                    if (filter.filter)
                        qp[filter.value] = name;
                    if (filter.value == "mobile_no" && filter.filter)
                        delete qp["name"];
                });
            }
            Object.keys(qp).forEach(key => {
                if (qp[key] === undefined) {
                    delete qp[key]; // Delete the key if the value is undefined
                }
            });
            loadUrl("/trendutube/v1/admin-order-processing/orders/details", 0, Object.assign({}, qp));
        }
        else {
            loadUrl("/trendutube/v1/admin-order-processing/orders/details", 0, {});
        }
    }
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(SearchToolBar, { onChange: onSearchChange, filters: filters, date: true }, void 0) }), void 0), _jsx(StreamNav, Object.assign({}, {
                loadNextPage, loadPage, stream, maxPage,
                getUrl: (page) => `/trendutube/v1/admin-order-processing/orders/details/${page}`
            }), void 0), orders ? _jsx("div", Object.assign({ style: { margin: '1em' } }, { children: _jsx(Box, Object.assign({ sx: { width: "100%", backgroundColor: "white", padding: "20px" } }, { children: _jsx(DataGrid, { getRowId: (row) => row.id, rows: orders, columns: columns, initialState: {
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }, hideFooterPagination: true, checkboxSelection: false, isRowSelectable: () => false }, void 0) }), void 0) }), void 0) : null] }, void 0));
};
export const OrderInfoList = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
