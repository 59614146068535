import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
export const CategoryTreeView = ({ categories, onSelectCategory, selectedCategoryUid, }) => {
    const [openCategories, setOpenCategories] = useState([]);
    const handleToggle = (uid) => {
        if (openCategories.includes(uid)) {
            setOpenCategories(openCategories.filter(item => item !== uid));
        }
        else {
            setOpenCategories([...openCategories, uid]);
        }
    };
    const renderTree = (nodes, level = 0) => (nodes.map(node => {
        const isLeafNode = !node.sub_categories || node.sub_categories.length === 0;
        return (_jsxs("div", { children: [_jsxs(ListItem, Object.assign({ sx: { paddingLeft: (level + 1) * 2, backgroundColor: level === 0 ? '#F7FBFE' : '#FFFFFF' } }, { children: [node.sub_categories && node.sub_categories.length > 0 ? (_jsx("div", Object.assign({ onClick: () => handleToggle(node.uid) }, { children: openCategories.includes(node.uid) ? _jsx(ExpandLess, { sx: { color: '#4382B1' } }, void 0) : _jsx(ExpandMore, { sx: { color: '#4382B1' } }, void 0) }), void 0)) : (_jsx("div", { style: { width: '24px' } }, void 0)), _jsx(ListItemText, { primary: node.category_name, sx: {
                                color: level === 0 ? '#4382B1' : '#515456', marginLeft: '3px'
                            } }, void 0), _jsx(ListItemSecondaryAction, { children: isLeafNode && _jsx(Checkbox, { checked: node.uid === selectedCategoryUid, onChange: () => onSelectCategory(node.uid), onClick: event => event.stopPropagation(), style: { color: 'gray' } }, void 0) }, void 0)] }), void 0), node.sub_categories && node.sub_categories.length > 0 && (_jsx(Collapse, Object.assign({ in: openCategories.includes(node.uid), timeout: "auto", unmountOnExit: true }, { children: _jsx(List, Object.assign({ component: "div", disablePadding: true }, { children: renderTree(node.sub_categories, level + 1) }), void 0) }), void 0))] }, node.uid));
    }));
    return (_jsx(List, { children: renderTree(categories) }, void 0));
};
