import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../saga/saga-context";
import container from "./style";
import { useEffect, useState } from "react";
import { useSubmit } from "../../saga/use-submit";
import { usePagination } from "../../saga/use-pagination";
import { Box, Button, Dialog, Grid, Switch, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { global_org_uid } from "../../config";
const textFieldStyles = {
    backgroundColor: "#F6F8F9",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
            maxHeight: '40px',
            maxWidth: '400px',
        },
        "& input": {
            color: "black",
            padding: "10px 12px",
            height: "100%",
            boxSizing: "border-box",
        },
        display: "flex",
        alignItems: "center",
    },
};
const ConfigInternal = (props) => {
    const { submit, error, status } = useSubmit();
    const [addDetails, setAddDetails] = useState(false);
    useEffect(() => {
        if (status === "ok") {
            toast.success("Details added Successfully");
            setAddDetails(false);
            setDialogOpen(false);
            setEditCourier("");
            setUpdatedKeys({});
            loadPage(paging === null || paging === void 0 ? void 0 : paging.page);
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const queryParams = { page_size: 100 };
    const { data: couriers, loadPage, paging } = usePagination(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/couriers-list`, { queryParams });
    const handleSubmit = () => {
        const isInvalid = deliveryDetails.keys.some((item) => item === "" ||
            item === null ||
            item === undefined);
        if (!deliveryDetails.name || deliveryDetails.name == null || deliveryDetails.name && deliveryDetails.name.length == 0) {
            toast.error("Provide courier name");
        }
        else if (deliveryDetails && deliveryDetails.keys.length == 0 || isInvalid) {
            toast.error("Provide courier authentication fields");
        }
        else {
            submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/couriers/add`, { body: Object.assign({}, deliveryDetails) });
        }
    };
    const [deliveryDetails, setDeliveryDetails] = useState({
        name: '',
        keys: [], // List of keys
    });
    // Handle courier name change
    const handleCourierNameChange = (event) => {
        setDeliveryDetails(Object.assign(Object.assign({}, deliveryDetails), { name: event.target.value }));
    };
    const handleKeyChange = (index, value) => {
        const updatedKeys = [...deliveryDetails.keys];
        updatedKeys[index] = value; // Update the specific key
        setDeliveryDetails(Object.assign(Object.assign({}, deliveryDetails), { keys: updatedKeys }));
    };
    // Handle adding a new key
    const handleAddKey = () => {
        setDeliveryDetails(Object.assign(Object.assign({}, deliveryDetails), { keys: [...deliveryDetails.keys, ''] }));
    };
    const [updatedKeys, setUpdatedKeys] = useState({});
    // Handler to update key values
    const handleKeyValueChange = (keyName, newValue) => {
        setUpdatedKeys((prev) => (Object.assign(Object.assign({}, prev), { [keyName]: newValue })));
    };
    const handleChange = (courier) => {
        submit(`/trendutube/v1/config-management/admin/couriers/${courier.uid}/update-courier-status`, { body: { enabled: !courier.service_available } });
    };
    const handleUpdateCourier = (courier) => {
        const hasEmptyValues = Object.values(updatedKeys).some(value => value === "" || value === null || value === undefined);
        if (hasEmptyValues) {
            toast.error("Invalid key exists in the inputs. Please check and update the values.");
        }
        else {
            submit(`/trendutube/v1/config-management/admin/couriers/${courier.uid}/update-courier`, { body: { authentication: updatedKeys } });
        }
    };
    const [editCourier, setEditCourier] = useState('');
    const handleCancel = () => {
        setUpdatedKeys({});
        console.log(updatedKeys);
        setEditCourier("");
    };
    const [dialogOpen, setDialogOpen] = useState(false);
    const [age, setAge] = useState('');
    const [open, setOpen] = useState(false);
    const handleCourierChange = (event) => {
        setAge(event.target.value);
    };
    const handleClose = () => {
        setAddDetails(false);
        setDialogOpen(false);
        setEditCourier("");
        setUpdatedKeys({});
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return (_jsxs("div", { children: [couriers ? _jsxs("div", Object.assign({ style: { width: '100%' } }, { children: [_jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' } }, { children: addDetails ? null : _jsx(_Fragment, { children: _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => setAddDetails(!addDetails), sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Add Courier" }), void 0) }, void 0) }), void 0), _jsx(Box, Object.assign({ sx: { padding: '20px' } }, { children: couriers && couriers.map((courier) => (courier.uid !== editCourier ? _jsxs(Box, Object.assign({ sx: { marginBottom: '20px', padding: '20px', backgroundColor: '#f9f9f9' } }, { children: [_jsxs(Typography, Object.assign({ variant: "h6" }, { children: ["Courier Name: ", courier.name] }), void 0), _jsx(Switch, { checked: courier.service_available, onChange: () => handleChange(courier), inputProps: { 'aria-label': 'controlled' }, disabled: courier.uid !== editCourier }, void 0), _jsx(Grid, Object.assign({ container: true, spacing: 2, sx: { marginTop: '10px' } }, { children: courier.keys.map((keyValue, keyIndex) => {
                                        var _a;
                                        return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(TextField, { fullWidth: true, label: `${keyValue.key}`, value: (_a = updatedKeys[keyValue.key_uid]) !== null && _a !== void 0 ? _a : keyValue.key, onChange: (e) => handleKeyValueChange(keyValue.key_uid, e.target.value), variant: "outlined", disabled: courier.uid !== editCourier }, void 0) }), keyIndex));
                                    }) }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => setEditCourier(courier.uid), sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Edit" }), void 0)] }), courier.uid) :
                            _jsxs(Box, Object.assign({ sx: { marginBottom: '20px', padding: '20px', backgroundColor: '#f9f9f9' } }, { children: [_jsxs(Typography, Object.assign({ variant: "h6" }, { children: ["Courier Name: ", courier.name] }), void 0), _jsx(Switch, { checked: courier.service_available, onChange: () => handleChange(courier), inputProps: { 'aria-label': 'controlled' } }, void 0), _jsx(Grid, Object.assign({ container: true, spacing: 2, sx: { marginTop: '10px' } }, { children: courier.keys.map((keyValue, keyIndex) => {
                                            var _a;
                                            return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(TextField, { fullWidth: true, label: `${keyValue.key}`, value: (_a = updatedKeys[keyValue.key_uid]) !== null && _a !== void 0 ? _a : keyValue.key, onChange: (e) => handleKeyValueChange(keyValue.key_uid, e.target.value), variant: "outlined" }, void 0) }), keyIndex));
                                        }) }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => handleUpdateCourier(courier), sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Update" }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: handleCancel, sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Cancel" }), void 0)] }), courier.uid))) }), void 0)] }), void 0) : null, _jsx(Dialog, Object.assign({ open: addDetails }, { children: _jsx(Box, Object.assign({ sx: { backgroundColor: 'white', padding: '30px', marginBottom: "25px" } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(Typography, Object.assign({ sx: { marginBottom: '5px' } }, { children: "Courier Name" }), void 0), _jsx(TextField, { fullWidth: true, name: "name", id: "name", value: deliveryDetails.name, onChange: handleCourierNameChange }, void 0)] }), void 0), deliveryDetails.keys.map((key, index) => (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsxs(Typography, Object.assign({ sx: { marginBottom: '5px' } }, { children: ["Key ", index + 1] }), void 0), _jsx(TextField, { fullWidth: true, value: key, onChange: (e) => handleKeyChange(index, e.target.value) }, void 0)] }), index))), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: handleAddKey }, { children: "Add Key" }), void 0), _jsx(Button, Object.assign({ variant: "contained", onClick: handleSubmit, sx: { marginLeft: '10px' } }, { children: "Save" }), void 0), _jsx(Button, Object.assign({ variant: "contained", onClick: handleClose, sx: { marginLeft: '10px' } }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }, void 0));
};
export const CouriersDetails = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
