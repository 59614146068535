import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import { useProgressBar } from "../../components/ProgressBar";
import { useSubmit } from "../../saga/use-submit";
import { emailRegex, formatCurrency, localDatetime } from "../../utils";
import { getNextPossibleOrderStatuses, orderStatusToHumanReadable } from "./status";
import { OrderStatusBadge } from "./status-view";
import { TableCell, TableRow } from "@mui/material";
export const OrderListComponent = (props) => {
    const { data: order, index } = props;
    const { productstyle } = useStyles("productstyle");
    const { id, order_date, status, delivery_slot, customer_uid, total_amount, total_shipping_charge, total_discount_amount, net_amount_with_tips, payment_mode, net_amount, last_payment_status, payment_order_id, order_id, delivery_items, shipping_gst_amount, shipping_gst_rate, shipping_gst_enable_flag, tip_amount, updated_dateTime, items, order_items, buyer, shipping_address } = order;
    const { personal_profile, login_profile, buyer_profile } = buyer || {};
    const { first_name, last_name, full_name } = personal_profile || {};
    const { address: addresses, email, mobile_no } = buyer_profile || {};
    const { username } = login_profile || {};
    let username_email, username_mobile_no;
    const { address_line1, address_line2, city, pin_code, landmark, location } = shipping_address || {};
    if (emailRegex.test(username))
        username_email = username || email;
    else
        username_mobile_no = username || mobile_no;
    const { submit, isFetching, error, status: reqStatus } = useSubmit();
    const setLoading = useProgressBar();
    useEffect(() => setLoading(isFetching), [isFetching]);
    useEffect(() => {
        if (error)
            toast.error(error.message);
    }, [error]);
    useEffect(() => {
        if (reqStatus == "ok") {
            toast.success("Order Item Status Updated", {});
        }
    }, [reqStatus]);
    const nextStatuses = getNextPossibleOrderStatuses(order);
    nextStatuses.push({ status: order.status, readable: orderStatusToHumanReadable(status) });
    return _jsxs(TableRow, Object.assign({ sx: { '& td, & th': { padding: '8px 16px' } } }, { children: [_jsx(TableCell, Object.assign({ sx: { minWidth: '150px' } }, { children: localDatetime(order.order_date) }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '100px' } }, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/orders/${order.id}/details` }, { children: order.order_id }), void 0) }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '80px' } }, { children: order.id }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '120px' } }, { children: _jsx(OrderStatusBadge, { status: order.status }, void 0) }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '100px' } }, { children: order.payment_mode }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '100px' } }, { children: formatCurrency(order.net_amount_with_tips) }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '200px', maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }, { children: _jsxs("div", Object.assign({ style: {
                        display: 'flex',
                        flexDirection: 'column',
                        lineHeight: '1.6em',
                    } }, { children: [_jsx("strong", { children: `${first_name || ''} ${last_name || ''}` }, void 0), _jsx("span", { children: `${address_line2 || ''}, ${city || ''}` }, void 0)] }), void 0) }), void 0), _jsx(TableCell, Object.assign({ sx: { minWidth: '200px' } }, { children: _jsx("div", Object.assign({ style: {
                        display: 'flex',
                        flexDirection: 'column',
                        lineHeight: '1.6em',
                        fontWeight: 600,
                    } }, { children: (order.delivery_items || []).map((del_item, idx) => (_jsx("div", { children: del_item.store.store_name }, idx))) }), void 0) }), void 0)] }), index);
};
