import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { DragDrop } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { Input } from "../../../components/Form/fields-extended";
import { X } from 'react-feather';
import { SwitchInput } from "../../../components/SwitchInput";
import { Box, Button, Drawer, Grid, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
export const ProductVariantEditComponent = (props) => {
    const { product, isOpen, onClose, updateProductVariant, productVariantUid, onImageDelete, onVideoDelete, updateVariantListing, onReloadProduct } = props;
    const isSmallScreen = useMediaQuery("(max-width:900px)");
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: isOpen, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '20px' },
        }, onClose: () => onClose(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => onClose(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Edit Product Variant" }), void 0)] }), void 0) }), void 0), _jsx(ProductVariantEdit, { productVariantUid: productVariantUid, product: product, onReloadProduct: onReloadProduct, updateProductVariant: updateProductVariant, updateVariantListing: updateVariantListing, onImageDelete: onImageDelete, onVideoDelete: onVideoDelete }, void 0)] }), void 0);
};
export const ProductVariantEdit = (props) => {
    const { product, updateProductVariant, productVariantUid, onImageDelete, onVideoDelete, updateVariantListing, onReloadProduct } = props;
    const variant = product.variants.find(v => v.product_variant_uid == productVariantUid);
    const { product_variant_uid, product_variant_price, product_variant_stock, variant_listing_flag, variant_value_inst, values } = variant || {};
    const { variant_types = [], uid } = product;
    const [variation, setVariation] = useState(values);
    const [image_uids, setImageUids] = useState([]);
    const [video_uids, setVideoUids] = useState([]);
    const ref = useRef(null);
    const [nocloud_image_uids, setNoCloudImageUids] = useState([]);
    const [nocloud_video_uids, setNoCloudVideoUids] = useState([]);
    useEffect(() => {
        if (variant_value_inst) {
            const value = variant_value_inst.reduce((acc, vara) => {
                const key = vara.variant_value_of[0].variant_type_name;
                const val = vara.variant_name;
                acc[key] = val;
                return acc;
            }, {});
            setVariation(Object.assign({}, value));
        }
    }, []);
    const onChange = (value) => {
        setVariation(Object.assign(Object.assign({}, variation), value));
    };
    const onSubmit = (formData) => {
        const { product_variant } = formData || {};
        const { product_variant_price, product_variant_stock, variant_listing_flag } = product_variant || {};
        if (Object.keys(variation || {}).length != variant_types.length)
            toast.error("Please choose all types");
        else if (!product_variant_price)
            toast.error("Please specify price");
        else if (!product_variant_stock)
            toast.error("Please specify stock");
        else {
            const body = { variation, product_variant_price, product_variant_stock, image_uids, variant_listing_flag, video_uids };
            updateProductVariant(productVariantUid, body);
            // alert(JSON.stringify(body))
        }
    };
    const reWriteUrl = (im) => (nocloud_image_uids === null || nocloud_image_uids === void 0 ? void 0 : nocloud_image_uids.findIndex(item => item == im.uid)) > -1 ? `${im.url}?nocloud=1` : im.url;
    const reWriteVideoUrl = (im) => (nocloud_video_uids === null || nocloud_video_uids === void 0 ? void 0 : nocloud_video_uids.findIndex(item => item == im.uid)) > -1 ? `${im.url}?nocloud=1` : im.url;
    const { action_btn_ms } = useStyles("action_btn_ms");
    const handleSwitchInput = (pvariant_uid) => {
        // alert(pvariant_uid)
        return (event) => {
            // alert(pvariant_uid)
            updateVariantListing(pvariant_uid, event.target.checked);
        };
    };
    return _jsxs("div", { children: [variant_types.map(variantType => _jsx(VariantRadioComponent, { onChange: onChange, variantType: variantType, variation: variation }, void 0)), _jsx("div", { children: _jsxs(Form, Object.assign({ name: "product_variant", onSubmit: onSubmit }, { children: [_jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: "Product Variant Price" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_price", value: product_variant_price }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: "Product Variant Stock" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_stock", value: product_variant_stock }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 600, marginTop: 10 } }, { children: "Is Product Variant Listed ?" }), void 0), _jsx("div", { children: _jsx(SwitchInput, { value: variant_listing_flag === true, backgroundColor: "#045998", onChange: handleSwitchInput(product_variant_uid || "") }, void 0) }, void 0), _jsx("button", Object.assign({ css: { display: "none" }, ref: ref }, { children: "Save" }), void 0)] }), void 0) }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Images" }), void 0), _jsxs("div", Object.assign({ css: { marginTop: 10 } }, { children: [_jsx(DragDrop, { onImagesUpload: (images) => {
                                    onReloadProduct();
                                }, entity: "product_variant", uid: variant === null || variant === void 0 ? void 0 : variant.product_variant_uid }, void 0), _jsx("div", Object.assign({ css: { display: 'flex', justifyContent: "space-between", flexWrap: "wrap", overflow: "hidden", border: "1px solid #bababa", padding: "1em", borderRadius: ".5em" } }, { children: variant && (variant.images || []).map(image => _jsxs("div", Object.assign({ css: { marginBottom: "10px", height: 124, width: 124, position: "relative" } }, { children: [_jsx("img", { height: 124, width: 124, css: { borderRadius: "6px" }, src: reWriteUrl(image) }, void 0), _jsx("div", Object.assign({ onClick: onImageDelete(image.uid), css: {
                                                position: "absolute", padding: 3, right: 8, bottom: 8, height: 20,
                                                borderRadius: 4, backgroundColor: "#222", cursor: "pointer",
                                                display: "flex", alignContent: "center"
                                            } }, { children: _jsx(X, { size: 14, color: "white" }, void 0) }), void 0)] }), void 0)) }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Videos" }), void 0)] }, void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: "Save" }), void 0) }), void 0)] }, void 0);
};
export const VariantRadioComponent = (props) => {
    const { variantType, onChange, variation } = props;
    const handleRadio = (e) => {
        onChange({ [variantType.variant_type_name]: e.currentTarget.value });
    };
    const handleSelection = (value) => {
        // Trigger onChange with the selected value
        onChange({ [variantType.variant_type_name]: value });
    };
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: { color: "#111", fontWeight: 600, fontSize: "18px", marginTop: 10 } }, { children: variantType.variant_type_name }), void 0), _jsxs("div", { children: [variantType.variant_type == "color" ?
                        _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: (variantType.variant_values || []).map(variantValue => (_jsxs(Grid, Object.assign({ item: true, xs: 3 }, { children: [" ", _jsx(ColorRadioComponent, { value: variantValue.color, label: variantValue.name, onSelect: handleSelection, isSelected: variation ? variantValue.name === variation[variantType.variant_type_name] : false }, void 0)] }), variantValue.name))) }), void 0)
                        : null, variantType.variant_type == "string" ?
                        _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: (variantType.variant_values || []).map(variantValue => (_jsxs(Grid, Object.assign({ item: true, xs: 3 }, { children: [" ", _jsx(VariantBox, { value: variantValue.name, label: variantValue.name, onSelect: handleSelection, isSelected: variation ? variantValue.name === variation[variantType.variant_type_name] : false }, void 0)] }), variantValue.name))) }), void 0) : null, variantType.variant_type == "image" ?
                        _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: (variantType.variant_values || []).map(variantValue => (_jsxs(Grid, Object.assign({ item: true, xs: 3 }, { children: [" ", _jsx(ImageRadioComponent, { value: variantValue.image.url, label: variantValue.name, onSelect: handleSelection, isSelected: variation ? variantValue.name === variation[variantType.variant_type_name] : false }, void 0)] }), variantValue.name))) }), void 0) : null] }, void 0)] }, void 0);
};
const VariantBox = ({ value, label, onSelect, isSelected }) => {
    return (_jsx(Box, Object.assign({ sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px',
            margin: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: isSelected ? 'red' : 'transparent',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f5f5f5', // Hover effect
            },
        }, onClick: () => onSelect(value) }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: label }), void 0) }), void 0));
};
const ColorRadioComponent = ({ value, label, onSelect, isSelected }) => {
    return _jsxs(Box, Object.assign({ sx: {
            textAlign: 'center',
            margin: '8px',
            padding: '4px',
            backgroundColor: isSelected ? '#fbe8e8' : 'transparent',
            border: isSelected ? `2px solid ${value}` : '2px solid transparent',
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out', // Smooth transitions for hover and selection effects
        } }, { children: [_jsx(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    backgroundColor: value,
                    cursor: 'pointer',
                    '&:hover': {
                        borderColor: '#888',
                        backgroundColor: isSelected ? '#fbdcdc' : '#f5f5f5', // Subtle hover effect
                    },
                    transition: 'all 0.3s ease-in-out', // Smooth hover effects
                }, onClick: () => onSelect(label) }, void 0), _jsx(Typography, Object.assign({ variant: "body2", sx: {
                    marginTop: '8px',
                    fontWeight: isSelected ? 600 : 400,
                    color: isSelected ? value : 'inherit',
                    transition: 'color 0.3s ease-in-out', // Smooth color change
                } }, { children: label }), void 0)] }), void 0);
    // <div css={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
    //     <input name={variantTypeName} value={variantValue.name} css={{ marginRight: "12px" }} type="radio" onChange={onChange} checked={selectedValue === variantValue.name} />
    //     <div css={{
    //         width: 20,
    //         height: 20,
    //         fontWeight: 500,
    //         margin: "4px 12px 4px 4px",
    //         borderRadius: 4,
    //         backgroundColor: variantValue.color
    //     }}></div>
    //     <div>{variantValue.name}</div>
    // </div>
};
const StringRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange, selectedValue } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "4px" }, onChange: onChange, type: "radio", checked: selectedValue === variantValue.name }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
const ImageRadioComponent = ({ value, label, onSelect, isSelected }) => {
    return _jsxs(Box, Object.assign({ sx: {
            textAlign: 'center',
            margin: '8px',
            padding: '4px',
            backgroundColor: isSelected ? '#fbe8e8' : 'transparent',
            border: isSelected ? `2px solid red` : '2px solid transparent',
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out', // Smooth transitions for hover and selection effects
        } }, { children: [_jsx(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    backgroundImage: `url(${value})`,
                    '&:hover': {
                        borderColor: '#888',
                        backgroundColor: isSelected ? '#fbdcdc' : '#f5f5f5', // Subtle hover effect
                    },
                    transition: 'all 0.3s ease-in-out', // Smooth hover effects
                }, onClick: () => onSelect(label) }, void 0), _jsx(Typography, Object.assign({ variant: "body2", sx: {
                    marginTop: '8px',
                    fontWeight: isSelected ? 600 : 400,
                    color: isSelected ? value : 'inherit',
                    transition: 'color 0.3s ease-in-out', // Smooth color change
                } }, { children: label }), void 0)] }), void 0);
};
