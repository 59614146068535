var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleableComponent, styling } from "@exprzn/react-style-lib";
export class InputEx extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false } = this.props;
        const { errorStyle, input } = this;
        function change(value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { css: [input], onChange: change, value: value, tabIndex: readonly ? -1 : 0 }, void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: errorStyle }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
    componentDidMount() {
        //Utter nonsense, bcos onChange takes event object not plain value
        // if (this.props.value)
        // this.props.onChange?.(this.props.value)
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputEx.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputEx.prototype, "input", void 0);
export class InputDiscountEx extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false } = this.props;
        const { errorStyle, input } = this;
        function change(value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { style: { backgroundColor: ' #F6F8F9', width: '100%', border: 'none', padding: '10px' }, onChange: change, value: value, tabIndex: readonly ? -1 : 0 }, void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: errorStyle }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
    componentDidMount() {
        //Utter nonsense, bcos onChange takes event object not plain value
        // if (this.props.value)
        // this.props.onChange?.(this.props.value)
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputDiscountEx.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputDiscountEx.prototype, "input", void 0);
export class InputNewEx extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false } = this.props;
        const { errorStyle, input } = this;
        function change(value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { style: { border: 'none', backgroundColor: ' #F6F8F9', padding: '1px' }, onChange: change, value: value, tabIndex: readonly ? -1 : 0 }, void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: [errorStyle, { color: "red" }] }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
    componentDidMount() {
        //Utter nonsense, bcos onChange takes event object not plain value
        // if (this.props.value)
        // this.props.onChange?.(this.props.value)
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputNewEx.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputNewEx.prototype, "input", void 0);
export class Input extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false } = this.props;
        const { errorStyle, input } = this;
        function change(value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { css: [input], onChange: change, value: value, tabIndex: readonly ? -1 : 0, style: { backgroundColor: '#F6F8F9', border: 'none' } }, void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: [errorStyle, { color: "red" }] }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
    componentDidMount() {
        //Utter nonsense, bcos onChange takes event object not plain value
        // if (this.props.value)
        // this.props.onChange?.(this.props.value)
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], Input.prototype, "errorStyle", void 0);
__decorate([
    styling("inputstyle"),
    __metadata("design:type", Object)
], Input.prototype, "input", void 0);
export class InputDate extends StyleableComponent {
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input } = this;
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { type: "date", css: [input], onChange: onChange, value: value }, void 0), errors && errors.length > 0 ?
                    _jsxs("div", Object.assign({ css: errorStyle }, { children: [errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, ""), " "] }), void 0) : null] }, void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputDate.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputDate.prototype, "input", void 0);
export class InputFileEx extends StyleableComponent {
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input } = this;
        //
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { css: [input], onChange: onChange, type: "file" }, void 0), errors && errors.length > 0 ?
                    _jsxs("div", Object.assign({ css: errorStyle }, { children: [errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, ""), " "] }), void 0) : null] }, void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputFileEx.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputFileEx.prototype, "input", void 0);
export class PasswordInputEx extends StyleableComponent {
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input } = this;
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { css: [input], onChange: onChange, value: value, type: 'password' }, void 0), errors && errors.length > 0 ?
                    _jsxs("div", Object.assign({ css: errorStyle }, { children: [errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, ""), " "] }), void 0) : null] }, void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], PasswordInputEx.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], PasswordInputEx.prototype, "input", void 0);
export class HiddenInputEx extends StyleableComponent {
    // ref = createRef();
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input } = this;
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { css: [input], onChange: onChange, value: value, type: 'hidden' }, void 0), errors && errors.length > 0 ?
                    _jsxs("div", Object.assign({ css: errorStyle }, { children: [errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, ""), " "] }), void 0) : null] }, void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], HiddenInputEx.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], HiddenInputEx.prototype, "input", void 0);
export class Checkbox extends StyleableComponent {
    change(value) {
        var _a, _b;
        (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, value);
        this.setState({ value: value.target.checked });
    }
    render() {
        const { onChange, value, errors, readonly = false } = this.props;
        const { errorStyle, input } = this;
        console.log("value", value);
        if (this.state == null && typeof value != "undefined")
            this.setState({ value: !!value });
        // function change(value: ChangeEvent<HTMLInputElement>) {
        //     onChange?.(value)
        // }
        return _jsxs(_Fragment, { children: [" ", _jsx("input", { type: "checkbox", checked: this.state && this.state.value == true, css: [input], onChange: this.change.bind(this), tabIndex: readonly ? -1 : 0 }, void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: errorStyle }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
    componentDidMount() {
        //Utter nonsense, bcos onChange takes event object not plain value
        // if (this.props.value)
        // this.props.onChange?.(this.props.value)
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], Checkbox.prototype, "errorStyle", void 0);
__decorate([
    styling("inputcheckbox"),
    __metadata("design:type", Object)
], Checkbox.prototype, "input", void 0);
export class InputArea extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false, rows, cols } = this.props;
        const { errorStyle, input } = this;
        function change(value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
        return _jsxs(_Fragment, { children: [" ", _jsx("textarea", { rows: rows || 3, cols: cols || 80, css: [input], onChange: change, value: value, tabIndex: readonly ? -1 : 0, style: { backgroundColor: '#F6F8F9', border: 'none' } }, void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: errorStyle }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
    componentDidMount() {
        //Utter nonsense, bcos onChange takes event object not plain value
        // if (this.props.value)
        // this.props.onChange?.(this.props.value)
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputArea.prototype, "errorStyle", void 0);
__decorate([
    styling("inputstyle"),
    __metadata("design:type", Object)
], InputArea.prototype, "input", void 0);
