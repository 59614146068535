import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// import React from "react";
// import "./TreeStyles.css"; // Custom styles for the tree
// // Recursive Component to Render Tree Nodes
// export const RecursiveTree = ({ node }: { node: any }) => {
//   return (
//     <div className="tree-node">
//       <div className="node-label">
//         <img src={node.image?.url} alt={node.category_name} className="node-image" />
//         <span>{node.category_name}</span>
//       </div>
//       {node.sub_categories && node.sub_categories.length > 0 && (
//         <div className="tree-children">
//           {node.sub_categories.map((child: any) => (
//             <RecursiveTree key={child.category_name} node={child} />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };
import { Box, Button, Drawer, IconButton, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { CategoryAddInternal } from '../add';
import { CategorySubCatInternal } from '../sub-category-add';
import { CategorynewEditInternal } from '../edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const TreeComponent = ({ data, pageReload, deleteCat }) => {
    // Convert your hierarchical data into a structure suitable for react-d3-tree
    const convertData = (category) => {
        return {
            uid: category.uid,
            category_name: category.category_name,
            name: category.uid,
            category_description: category.category_description,
            imageurl: category.image != null ? category.image.url : null,
            image: category.image,
            children: category.sub_categories ? category.sub_categories.map(convertData) : []
        };
    };
    const [selectedNode, setSelectedNode] = useState(null);
    const [isAddMode, setIsAddMode] = useState(false); // Whether to add or edit category
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleAddCategory = () => {
        setSelectedNode(null); // No specific node selected for add
        setIsAddMode(true); // Add mode
        setDrawerOpen(true); // Open the drawer
    };
    const handleDeleteCategory = (uid) => {
        setDrawerOpen(false);
        setSelectedNode(null);
        deleteCat(uid);
    };
    const handleEditCategory = (nodeDatum) => {
        setSelectedNode(nodeDatum);
        console.log("nodeDatum", nodeDatum);
        setIsAddMode(false); // Edit mode
        setDrawerOpen(true); // Open the drawer
    };
    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedNode(null);
    };
    const handleSave = () => {
        console.log(isAddMode ? "Added Category" : "Edited Category", selectedNode);
        setDrawerOpen(false); // Close the drawer after saving
    };
    const handleToggleMode = (_event, newMode) => {
        setIsAddMode(newMode);
    };
    const treeData = [convertData(data)];
    const renderCustomNode = (rd3tNodeProps) => {
        const { nodeDatum, x, y } = rd3tNodeProps;
        const offsetX = -50; // Move right by 20 pixels
        const offsetY = -50;
        return (_jsxs("g", Object.assign({ transform: `translate(${offsetX}, ${offsetY})`, onClick: () => handleEditCategory(nodeDatum) }, { children: [nodeDatum.uid != "dx678" ? _jsx("rect", { width: "100", height: "130", rx: "10", ry: "10", fill: "#f5f5f5", stroke: "#ddd", strokeWidth: "1" }, void 0) : _jsx("rect", { width: "100", height: "130", rx: "10", ry: "10", fill: "white", stroke: "none", strokeWidth: "1" }, void 0), nodeDatum.uid != "dx678" && _jsx("image", { href: nodeDatum.imageurl, x: "10", y: "10", width: "80", height: "80", style: { borderRadius: '10px' } }, void 0), nodeDatum.uid != "dx678" ? _jsx("text", Object.assign({ x: "50", y: "110", textAnchor: "middle", fontSize: "12", "font-family": "Montserrat", style: { stroke: 'unset', fill: '#373737', strokeWidth: 'initial', fontSize: '13px' } }, { children: nodeDatum.category_name }), void 0) : _jsx("text", Object.assign({ x: "50", y: "110", textAnchor: "middle", fontSize: "20", "font-family": "Montserrat", style: { stroke: 'unset', fill: '#373737', strokeWidth: 'initial', fontSize: '20px' } }, { children: nodeDatum.category_name }), void 0)] }), void 0));
    };
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        const updateDimensions = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const DrawerHeader = styled('div')(({ theme }) => (Object.assign(Object.assign({ display: 'flex', alignItems: 'center', padding: theme.spacing(0, 1) }, theme.mixins.toolbar), { justifyContent: 'flex-end' })));
    return (_jsxs("div", Object.assign({ id: "treeWrapper", style: { width: '100%', height: '100vh' } }, { children: [_jsx(Tree, { data: treeData, orientation: "vertical", pathFunc: "step", renderCustomNodeElement: renderCustomNode, separation: { siblings: 1, nonSiblings: 1 }, translate: {
                    x: (dimensions.width * 0.8) / 2,
                    y: 100,
                }, depthFactor: 200, zoom: 0.7 }, void 0), _jsx(Drawer, Object.assign({ anchor: "right", open: drawerOpen, onClose: handleCloseDrawer, sx: {
                    width: "100", flexShrink: 1,
                } }, { children: _jsxs(Box, Object.assign({ sx: {
                        position: "relative",
                        height: "100%",
                        "&:hover .hover-box": {
                            opacity: 1,
                        },
                    } }, { children: [_jsx(Box, Object.assign({ className: "hover-box", sx: {
                                position: "fixed",
                                top: "50%",
                                transform: "translate(0, -50%)",
                                height: "60px",
                                width: "18px",
                                backgroundColor: "#045998",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
                                opacity: 0,
                                transition: "opacity 0.3s",
                            } }, { children: _jsx(IconButton, Object.assign({ onClick: handleCloseDrawer, sx: {
                                    color: "white", // Ensures the icon is visible
                                } }, { children: _jsx(ChevronRightIcon, {}, void 0) }), void 0) }), void 0), _jsxs(Box, Object.assign({ sx: {
                                width: 400,
                                padding: 2,
                                display: "flex",
                                flexDirection: "column",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    } }, { children: (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.uid) !== "dx678" ? _jsxs(_Fragment, { children: [_jsx("h3", Object.assign({ style: { marginRight: 10 } }, { children: isAddMode ? "Add Category" : `Edit ${selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.category_name}` }), void 0), _jsxs(ToggleButtonGroup, Object.assign({ size: 'small', value: isAddMode, exclusive: true, onChange: handleToggleMode, color: 'primary' }, { children: [_jsx(ToggleButton, Object.assign({ value: true }, { children: "Add" }), void 0), _jsx(ToggleButton, Object.assign({ value: false }, { children: "Edit" }), void 0)] }), void 0), selectedNode && _jsx(Button, Object.assign({ variant: "contained", onClick: () => { handleDeleteCategory(selectedNode.uid); } }, { children: "Delete" }), void 0)] }, void 0) : _jsx(_Fragment, { children: _jsx("h3", Object.assign({ style: { marginRight: 10 } }, { children: " Add Category" }), void 0) }, void 0) }), void 0), (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.uid) !== "dx678" ? (isAddMode ? (_jsx(CategorySubCatInternal, { uid: (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.uid) || "", pageReload: pageReload, handleCloseDrawer: handleCloseDrawer }, void 0)) : (_jsx(CategorynewEditInternal, { uid: (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.uid) || "", pageReload: pageReload, handleCloseDrawer: handleCloseDrawer }, void 0))) : (_jsx(CategoryAddInternal, { pageReload: pageReload, handleCloseDrawer: handleCloseDrawer }, void 0))] }), void 0)] }), void 0) }), void 0)] }), void 0));
};
export default TreeComponent;
