import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import dayjs from 'dayjs';
import { Button, Grid, Typography, Box, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate, setSingleDatePiker, singleDatePicker, setSelectedRange, selectedRange }) => {
    const handlePredefinedRange = (range) => {
        const today = dayjs();
        setSingleDatePiker(false);
        switch (range) {
            case 'This Week': {
                const startOfWeek = today.startOf('week');
                const endOfWeek = today.endOf('week');
                setStartDate(startOfWeek);
                setEndDate(endOfWeek);
                setSelectedRange('This Week');
                break;
            }
            case 'Last Week': {
                const startOfLastWeek = today.subtract(1, 'week').startOf('week');
                const endOfLastWeek = today.subtract(1, 'week').endOf('week');
                setStartDate(startOfLastWeek);
                setEndDate(endOfLastWeek);
                setSelectedRange('Last Week');
                break;
            }
            case 'Last 7 Days': {
                setStartDate(today.subtract(7, 'day'));
                setEndDate(today);
                setSelectedRange('Last 7 Days');
                break;
            }
            case 'Current Month': {
                setStartDate(today.startOf('month'));
                setEndDate(today.endOf('month'));
                setSelectedRange('Current Month');
                break;
            }
            case 'Next Month': {
                setStartDate(today.add(1, 'month').startOf('month'));
                setEndDate(today.add(1, 'month').endOf('month'));
                setSelectedRange('Next Month');
                break;
            }
            case 'Date Wise': {
                const todayDate = dayjs(); // Get today's date
                setStartDate(todayDate);
                setSingleDatePiker(true);
                setEndDate(todayDate); // Set both start and end dates to today
                setSelectedRange('Date Wise');
                break;
            }
            case 'Reset': {
                setStartDate(null);
                setEndDate(null);
                setSelectedRange('Reset');
                break;
            }
            default:
                break;
        }
    };
    const handleStartDateChange = (newStartDate) => {
        setStartDate(newStartDate);
        if (newStartDate && endDate && newStartDate.isAfter(endDate)) {
            setEndDate(newStartDate);
        }
    };
    const handleEndDateChange = (newEndDate) => {
        setEndDate(newEndDate);
        if (newEndDate && startDate && newEndDate.isBefore(startDate)) {
            setStartDate(newEndDate);
        }
    };
    return (_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsxs(Box, Object.assign({ display: "flex", p: 2, gap: 4 }, { children: [_jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "subtitle1", gutterBottom: true }, { children: "SELECT DATE RANGE" }), void 0), _jsx(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: ['This Week', 'Last Week', 'Last 7 Days', 'Current Month', 'Next Month', 'Date Wise'].map((range) => (_jsx(Button, Object.assign({ variant: "contained", onClick: () => handlePredefinedRange(range), sx: {
                                    backgroundColor: selectedRange === range ? '#045998' : '#DDF1FF',
                                    boxShadow: 'none',
                                    color: selectedRange === range ? '#ffffff' : '#000',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#045998',
                                        color: '#ffffff'
                                    },
                                } }, { children: range }), range))) }), void 0)] }, void 0), _jsx(Box, Object.assign({ flex: 1 }, { children: singleDatePicker ? _jsx(Grid, Object.assign({ container: true }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(DatePicker, { label: "Current Date", value: startDate, onChange: handleStartDateChange, slotProps: {
                                    textField: { fullWidth: true },
                                } }, void 0) }), void 0) }), void 0) :
                        _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(DatePicker, { label: "Start Date", value: startDate, onChange: handleStartDateChange, slotProps: {
                                            textField: { fullWidth: true },
                                        } }, void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(DatePicker, { label: "End Date", value: endDate, onChange: handleEndDateChange, slotProps: {
                                            textField: { fullWidth: true },
                                        } }, void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default DateRangePicker;
