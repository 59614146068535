import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { DragDrop } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { Checkbox, Input } from "../../../components/Form/fields-extended";
import { Box, Button, Drawer, Grid, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
export const ProductVariantAddComponent = (props) => {
    const { product, isOpen, onClose, addProductVariant } = props;
    const isSmallScreen = useMediaQuery("(max-width:900px)");
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: isOpen, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => onClose(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    marginBottom: '1rem'
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => onClose(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add Product Variant" }), void 0)] }), void 0) }), void 0), _jsx(ProductVariantAdd, { product: product, addProductVariant: addProductVariant }, void 0)] }), void 0);
};
export const ProductVariantAdd = (props) => {
    const { product, addProductVariant } = props;
    const { variant_types = [], uid } = product;
    const [variation, setVariation] = useState({});
    const [image_uids, setImageUids] = useState([]);
    const [video_uids, setVideoUids] = useState([]);
    const ref = useRef(null);
    const onChange = (value) => {
        setVariation(Object.assign(Object.assign({}, variation), value));
    };
    const onSubmit = (formData) => {
        const { product_variant } = formData || {};
        const { product_variant_price, product_variant_stock, variant_listing_flag } = product_variant || {};
        const keys = Object.keys(variation || {});
        if (keys.length != variant_types.length)
            toast.error(`Please choose all types: ${keys.join(",")}`, { toastId: "t1" });
        else if (!product_variant_price)
            toast.error("Please specify price", { toastId: "t2" });
        else if (!product_variant_stock)
            toast.error("Please specify stock", { toastId: "t3" });
        else {
            const body = { variation, product_variant_price, product_variant_stock, image_uids, variant_listing_flag, video_uids };
            addProductVariant(body);
            // alert(JSON.stringify(body))
        }
    };
    const { action_btn_ms } = useStyles("action_btn_ms");
    return _jsxs("div", Object.assign({ style: { padding: '0.5rem' } }, { children: [variant_types.map(variantType => _jsx(VariantRadioComponent, { onChange: onChange, variantType: variantType, selectedValue: variation[variantType === null || variantType === void 0 ? void 0 : variantType.variant_type_name] }, void 0)), _jsx("div", { children: _jsxs(Form, Object.assign({ name: "product_variant", onSubmit: onSubmit }, { children: [_jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: "Product Variant Price" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_price" }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: "Product Variant Stock" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_stock" }, { children: _jsx(Input, {}, void 0) }), void 0), _jsxs("div", Object.assign({ css: { marginTop: 20 } }, { children: ["List this product variant ?", _jsx(Field, Object.assign({ name: "variant_listing_flag" }, { children: _jsx(Checkbox, {}, void 0) }), void 0)] }), void 0), _jsx("button", Object.assign({ css: { display: "none" }, ref: ref }, { children: "Save" }), void 0)] }), void 0) }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Images" }), void 0), _jsx("div", Object.assign({ css: { marginTop: 10 } }, { children: _jsx(DragDrop, { onImagesUpload: (images) => {
                                setImageUids(images.map(im => im.uid));
                            }, entity: "product_variant" }, void 0) }), void 0)] }, void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: "Save" }), void 0) }), void 0)] }), void 0);
};
export const VariantRadioComponent = (props) => {
    const { variantType, onChange, selectedValue } = props;
    const handleRadio = (e) => {
        onChange({ [variantType.variant_type_name]: e.currentTarget.value });
    };
    const handleSelection = (value) => {
        // Trigger onChange with the selected value
        onChange({ [variantType.variant_type_name]: value });
    };
    return _jsxs("div", Object.assign({ style: { marginTop: '1rem' } }, { children: [_jsx("div", Object.assign({ css: { color: "#111", fontWeight: 600, fontSize: "18px", marginTop: 10 } }, { children: variantType.variant_type_name }), void 0), variantType.variant_type == "color" ?
                _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: (variantType.variant_values || []).map(variantValue => (_jsxs(Grid, Object.assign({ item: true, xs: 3 }, { children: [" ", _jsx(ColorRadioComponent, { value: variantValue.color, label: variantValue.name, onSelect: handleSelection, isSelected: variantValue.name === selectedValue }, void 0)] }), variantValue.name))) }), void 0)
                : null, variantType.variant_type == "string" ?
                _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: (variantType.variant_values || []).map(variantValue => (_jsxs(Grid, Object.assign({ item: true, xs: 3 }, { children: [" ", _jsx(VariantBox, { value: variantValue.name, label: variantValue.name, onSelect: handleSelection, isSelected: variantValue.name === selectedValue }, void 0)] }), variantValue.name))) }), void 0) : null, variantType.variant_type == "image" ?
                _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: (variantType.variant_values || []).map(variantValue => (_jsxs(Grid, Object.assign({ item: true, xs: 3 }, { children: [" ", _jsx(ImageRadioComponent, { value: variantValue.image.url, label: variantValue.name, onSelect: handleSelection, isSelected: variantValue.name === selectedValue }, void 0)] }), variantValue.name))) }), void 0)
                : null] }), void 0);
};
const VariantBox = ({ value, label, onSelect, isSelected }) => {
    return (_jsx(Box, Object.assign({ sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px',
            margin: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: isSelected ? 'red' : 'transparent',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f5f5f5', // Hover effect
            },
        }, onClick: () => onSelect(value) }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: label }), void 0) }), void 0));
};
export const ColorRadioComponent = ({ value, label, onSelect, isSelected }) => {
    return _jsxs(Box, Object.assign({ sx: {
            textAlign: 'center',
            margin: '8px',
            padding: '4px',
            backgroundColor: isSelected ? '#fbe8e8' : 'transparent',
            border: isSelected ? `2px solid ${value}` : '2px solid transparent',
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out', // Smooth transitions for hover and selection effects
        } }, { children: [_jsx(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    backgroundColor: value,
                    cursor: 'pointer',
                    '&:hover': {
                        borderColor: '#888',
                        backgroundColor: isSelected ? '#fbdcdc' : '#f5f5f5', // Subtle hover effect
                    },
                    transition: 'all 0.3s ease-in-out', // Smooth hover effects
                }, onClick: () => onSelect(label) }, void 0), _jsx(Typography, Object.assign({ variant: "body2", sx: {
                    marginTop: '8px',
                    fontWeight: isSelected ? 600 : 400,
                    color: isSelected ? value : 'inherit',
                    transition: 'color 0.3s ease-in-out', // Smooth color change
                } }, { children: label }), void 0)] }), void 0);
};
export const StringRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "4px" }, onChange: onChange, type: "radio" }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
export const ImageRadioComponent = ({ value, label, onSelect, isSelected }) => {
    return _jsxs(Box, Object.assign({ sx: {
            textAlign: 'center',
            margin: '8px',
            padding: '4px',
            backgroundColor: isSelected ? '#fbe8e8' : 'transparent',
            border: isSelected ? `2px solid red` : '2px solid transparent',
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out',
        } }, { children: [_jsx(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    backgroundImage: `url(${value})`,
                    '&:hover': {
                        borderColor: '#888',
                        backgroundColor: isSelected ? '#fbdcdc' : '#f5f5f5',
                    },
                    transition: 'all 0.3s ease-in-out',
                }, onClick: () => onSelect(label) }, void 0), _jsx(Typography, Object.assign({ variant: "body2", sx: {
                    marginTop: '8px',
                    fontWeight: isSelected ? 600 : 400,
                    color: isSelected ? value : 'inherit',
                    transition: 'color 0.3s ease-in-out',
                } }, { children: label }), void 0)] }), void 0);
};
