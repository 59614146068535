import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
const RefreshButton = () => {
    const handleRefresh = () => {
        window.location.reload(); // Refresh the page
    };
    return (_jsx(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', gap: 1 } }, { children: _jsxs(IconButton, Object.assign({ onClick: handleRefresh, color: "primary" }, { children: [_jsx(Typography, Object.assign({ variant: "body2", color: "primary" }, { children: "Refresh Page" }), void 0), _jsx(RefreshIcon, { fontSize: 'small' }, void 0)] }), void 0) }), void 0));
};
export default RefreshButton;
