import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { DragDrop } from "../../../components/DragDrop/index";
import { Form, Field } from "../../../components/Form";
import { InputEx } from "../../../components/Form/fields-extended";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useSubmit } from "../../../saga/use-submit";
import container from "./style";
import { global_org_uid } from "../../../config";
export const UploadComponentDemo = () => {
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const { submit, data, error } = useSubmit();
    const { submit: submitImage, data: dataImage, error: errorImage, } = useSubmit();
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [image_uid, setImage] = useState();
    const submitHandler = (formData) => {
        submit(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/add`, {
            body: Object.assign(Object.assign({}, formData.product_class), { image_uid }),
        });
    };
    const inputOpenFileRef = useRef(null);
    function showOpenFileDlg() {
        inputOpenFileRef &&
            inputOpenFileRef.current &&
            inputOpenFileRef.current.click();
    }
    function handleChange(event) {
        // console.log(event.target.files, typeof event.target.files, Array.isArray(event.target.files))
        // alert('change >' + JSON.stringify(event.target.files))
        const files = Object.keys(event.target.files).map((file) => URL.createObjectURL(event.target.files[file]));
        setFiles(files);
        setImages(event.target.files);
        event.preventDefault();
        // alert(files.length)
    }
    useEffect(() => {
        if (dataImage) {
            const { urls } = dataImage;
            setImage(urls[0].uid);
            // alert(urls[0].uid)
        }
    }, [dataImage]);
    function handleImageSubmit(data) {
        // alert(JSON.stringify(data))
        // const { images } = data?.upload
        submitImage("/image-service/images/upload?entity=product_class", {
            form: true,
            body: { images },
        });
    }
    return (_jsxs("div", Object.assign({ css: category }, { children: [_jsxs("div", { children: [" ", data && JSON.stringify(data || {})] }, void 0), _jsx("div", { children: error && JSON.stringify(error) }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsx(DragDrop, { entity: "product_class", onImagesUpload: setImage }, void 0), _jsxs(Form, Object.assign({ name: "upload", onSubmit: handleImageSubmit }, { children: [_jsx(Row, { children: _jsx("div", { children: files.map((f) => (_jsx("img", { src: f, height: "200" }, void 0))) }, void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ size: 2 }, { children: [_jsx("input", { ref: inputOpenFileRef, type: "file", accept: "image/*", style: { display: "none" }, onChange: handleChange }, void 0), _jsx("button", Object.assign({ type: "button", onClick: showOpenFileDlg, css: button }, { children: "Browse" }), void 0)] }), void 0), _jsx(Col, Object.assign({ size: 3 }, { children: _jsx("input", { type: "submit", value: "Upload", css: button }, void 0) }), void 0)] }, void 0)] }), void 0)] }, void 0) }, void 0) }, void 0), _jsx(Col, { children: _jsxs(Form, Object.assign({ name: "product_class", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("h1", { children: "Up Demo" }, void 0), _jsx("div", { children: "Class Name" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "product_class_name" }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: rowstyle }, { children: _jsx("input", { type: "submit", value: "Add Product Class", css: button }, void 0) }), void 0) }, void 0)] }), void 0) }, void 0)] }, void 0)] }), void 0));
};
export const UploadClassDemo = () => (_jsxs(SagaProvider, { children: [" ", _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(UploadComponentDemo, {}, void 0) }), void 0)] }, void 0));
