import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
const PredefinedVariantsComponent = ({ predefinedVariants, preselectedValues = [], addVariantTypeGroup }) => {
    const [selectedValues, setSelectedValues] = useState(new Set());
    const [preSelectedDefaultValues, setPreSelectedDefaultValues] = useState([]);
    useEffect(() => {
        if (preselectedValues && preselectedValues.length > 0) {
            const values = preselectedValues.map((type) => ({
                name: type.variant_type_name,
                values: type.variant_values.map((variant) => type.variant_type == "color" ? variant.color : type.variant_type == "string" ? variant.label : variant.image),
            }));
            console.log("select values", values);
            setPreSelectedDefaultValues(values);
        }
    }, []);
    const handleSelect = (value) => {
        setSelectedValues((prevSelected) => {
            const updatedSet = new Set(prevSelected);
            if (updatedSet.has(value)) {
                updatedSet.delete(value);
            }
            else {
                updatedSet.add(value);
            }
            return updatedSet;
        });
    };
    const handleAdd = (variant) => {
        const selectedVariant = {
            name: variant.name,
            type: variant.type,
            affects_pricing: true,
            values: variant.values
                .filter((variantValue) => selectedValues.has(variantValue.value))
                .map((variantValue) => ({
                name: variantValue.name,
                value: variantValue.value,
            })),
        };
        addVariantTypeGroup(selectedVariant);
    };
    return (_jsx("div", { children: predefinedVariants.map((variant) => (_jsxs(Box, Object.assign({ sx: { padding: '1.5rem', marginBottom: '1rem', marginTop: '1rem' } }, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    } }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: '18px', fontFamily: 'Inter' } }, { children: variant.name }), void 0), _jsx(Button, Object.assign({ size: 'small', sx: { border: '1px solid #045998E5', backgroundColor: '#60B6F638' }, onClick: () => handleAdd(variant) }, { children: "Add" }), void 0)] }), void 0), _jsx(Grid, Object.assign({ container: true, spacing: 2, sx: { marginTop: '1rem' } }, { children: variant.values.map((variantValue) => {
                        const isSelected = selectedValues.has(variantValue.value);
                        let isDefaultSelect = false;
                        if (preSelectedDefaultValues && preSelectedDefaultValues.length > 0) {
                            const typeExist = preSelectedDefaultValues.find((type) => type.name == variant.name);
                            if (typeExist && typeExist.values && typeExist.values.length > 0) {
                                isDefaultSelect = typeExist.values.includes(variantValue.value);
                                console.log("default", isDefaultSelect, variantValue.value);
                            }
                        }
                        return (_jsx(Grid, Object.assign({ item: true, xs: 2 }, { children: variant.type === 'color' ? (_jsx(Box, Object.assign({ sx: {
                                    padding: isSelected || isDefaultSelect ? '2.5px' : '0px',
                                    border: isSelected || isDefaultSelect ? '2px solid gray' : '1px solid #ccc',
                                    width: '35px',
                                    height: '35px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                } }, { children: _jsx(Box, { sx: {
                                        width: isSelected || isDefaultSelect ? '30px' : '35px',
                                        height: isSelected || isDefaultSelect ? '30px' : '35px',
                                        backgroundColor: variantValue.value,
                                        cursor: 'pointer',
                                        borderRadius: isSelected || isDefaultSelect ? '50%' : '0%',
                                        transition: 'all 0.1s ease-in-out',
                                        opacity: isDefaultSelect ? 0.5 : 1,
                                        pointerEvents: isDefaultSelect ? 'none' : 'auto',
                                    }, onClick: () => {
                                        if (!isDefaultSelect) {
                                            handleSelect(variantValue.value);
                                        }
                                    } }, void 0) }), void 0)) : variant.type === 'string' ? (_jsx(Box, Object.assign({ sx: {
                                    padding: isSelected || isDefaultSelect ? '2.5px' : '0px',
                                    border: isSelected || isDefaultSelect ? '2px solid gray' : '1px solid #ccc',
                                    width: '35px',
                                    height: '35px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                        width: isSelected || isDefaultSelect ? '30px' : '35px',
                                        height: isSelected || isDefaultSelect ? '30px' : '35px',
                                        backgroundColor: '#E4E4EC',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        borderRadius: isSelected || isDefaultSelect ? '50%' : '0%',
                                        cursor: 'pointer',
                                        transition: 'all 0.1s ease-in-out',
                                        opacity: isDefaultSelect ? 0.5 : 1,
                                        pointerEvents: isDefaultSelect ? 'none' : 'auto',
                                    }, onClick: () => handleSelect(variantValue.value) }, { children: _jsx(Typography, Object.assign({ sx: {
                                            fontSize: '12px',
                                            textAlign: 'center',
                                            color: isSelected || isDefaultSelect ? 'blue' : 'black',
                                        } }, { children: variantValue.value }), void 0) }), void 0) }), void 0)) : (_jsx(Box, Object.assign({ sx: {
                                    padding: isSelected || isDefaultSelect ? '2.5px' : '0px',
                                    border: isSelected || isDefaultSelect ? '2px solid gray' : '1px solid #ccc',
                                    width: '35px',
                                    height: '35px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                        width: isSelected || isDefaultSelect ? '30px' : '35px',
                                        height: isSelected || isDefaultSelect ? '30px' : '35px',
                                        border: '1px solid #ccc',
                                        cursor: 'pointer',
                                        borderRadius: isSelected || isDefaultSelect ? '50%' : '0%',
                                        transition: 'all 0.1s ease-in-out',
                                        opacity: isDefaultSelect ? 0.5 : 1,
                                        pointerEvents: isDefaultSelect ? 'none' : 'auto',
                                    }, onClick: () => handleSelect(variantValue.value) }, { children: _jsx("img", { src: `http://api-dev.trendutube.com/image-service/images/retrieve/${variantValue.value}`, alt: variantValue.name, style: {
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: isSelected || isDefaultSelect ? '50%' : '0%',
                                            objectFit: 'cover',
                                        } }, void 0) }), void 0) }), void 0)) }), variantValue.value));
                    }) }), void 0)] }), variant.name))) }, void 0));
};
export default PredefinedVariantsComponent;
