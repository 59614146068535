import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Grid, CardMedia, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, useMediaQuery, DialogContentText } from '@mui/material';
import { DragDrop } from '../../../components/DragDrop';
import { DeleteIcon, EditIcon } from '../../../components/Icon';
import { useSubmit } from '../../../saga/use-submit';
const VariantImageSelector = ({ variants, onReloadProduct, handleProductVariantEdit, handleProductVariantDelete }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedVariantUid, setSelectedVariantUid] = useState('');
    const handleDialogOpen = (variantUid) => {
        setSelectedVariantUid(variantUid);
        setOpenDialog(true);
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedVariantUid('');
    };
    const { submit, error, status } = useSubmit();
    const isSmallScreen = useMediaQuery("(max-width:900px)");
    const [selectedImages, setSelectedImages] = useState(variants.reduce((acc, variant) => {
        var _a;
        acc[variant.product_variant_uid] = ((_a = variant.images[0]) === null || _a === void 0 ? void 0 : _a.url) || '';
        return acc;
    }, {}));
    const handleImageSelect = (variantUid, imageUrl) => {
        setSelectedImages((prev) => (Object.assign(Object.assign({}, prev), { [variantUid]: imageUrl })));
    };
    const [variantDeleteConform, setVariantDeleteConform] = useState(false);
    const [variantDeleteId, setVariantDeleteId] = useState('');
    const handleDeleteVariantConform = (variantId) => {
        setVariantDeleteConform(true);
        setVariantDeleteId(variantId);
    };
    const handleProductVariantDeleteConform = () => {
        handleProductVariantDelete(variantDeleteId);
        setVariantDeleteId('');
        setVariantDeleteConform(false);
    };
    const handleProductVariantDeleteCancel = () => {
        setVariantDeleteId('');
        setVariantDeleteConform(false);
    };
    return (_jsxs(Grid, Object.assign({ container: true, className: 'grid-border', style: { rowGap: '18px', margin: '0 !important', padding: isSmallScreen ? '0' : '1rem', border: isSmallScreen ? '0' : 'solid 1px #ccc' } }, { children: [variants.filter(va => va.variant_value_inst && va.variant_value_inst.length > 0).map((variant) => (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, gap: 4 }, { children: _jsxs(Box, Object.assign({ className: "card-container", sx: { margin: '8px', backgroundColor: '#fff', height: "440px", boxShadow: '0px 0px 5.76px 0px #00000029', borderRadius: '10px', } }, { children: [_jsxs(Box, Object.assign({ sx: { position: 'relative', width: '100%', backgroundColor: '#E6F3FC', padding: '1rem' } }, { children: [_jsx(CardMedia, { component: "img", image: selectedImages[variant.product_variant_uid], sx: {
                                        objectFit: 'cover',
                                        padding: '0',
                                        height: '234px',
                                        borderRadius: '3%',
                                        maxWidth: '100%',
                                        display: 'block',
                                    } }, void 0), _jsxs(Box, Object.assign({ sx: {
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                        '&:hover': {
                                            opacity: 1,
                                        },
                                    } }, { children: [_jsxs(IconButton, Object.assign({ onClick: () => handleProductVariantEdit(variant.product_variant_uid), sx: {
                                                color: '#2E75A9',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 0.5,
                                                padding: '5px 5px',
                                                width: '100px',
                                                borderRadius: '3px',
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                                marginBottom: '8px',
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                },
                                            } }, { children: [_jsx(EditIcon, {}, void 0), _jsx("span", Object.assign({ style: {
                                                        color: '#2E75A9',
                                                        fontSize: '12px',
                                                        backgroundColor: 'transparent',
                                                    } }, { children: "Edit" }), void 0)] }), void 0), _jsxs(IconButton, Object.assign({ onClick: () => handleDeleteVariantConform(variant.product_variant_uid), sx: {
                                                color: '#2E75A9',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 0.5,
                                                padding: '5px 5px',
                                                width: '100px',
                                                borderRadius: '3px',
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                },
                                            } }, { children: [_jsx(DeleteIcon, {}, void 0), _jsx("span", Object.assign({ style: {
                                                        color: '#DE0606',
                                                        fontSize: '12px',
                                                        backgroundColor: 'transparent',
                                                    } }, { children: "Delete" }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx(Box, Object.assign({ sx: { padding: '1rem', backgroundColor: '#E6F3FC', height: '70px', paddingTop: '0' } }, { children: _jsxs("div", Object.assign({ className: "product-selector custom-scroll" }, { children: [_jsx("div", Object.assign({ className: "product-item" }, { children: variant.images.map((image) => (_jsx(Button, Object.assign({ sx: {
                                                width: 54,
                                                height: 54,
                                                margin: '1px',
                                                padding: 0,
                                                borderRadius: '25%',
                                                borderColor: selectedImages[variant.product_variant_uid] === image.url ? 'primary.main' : 'transparent',
                                                boxShadow: selectedImages[variant.product_variant_uid] === image.url ? 2 : 0,
                                            }, onClick: () => handleImageSelect(variant.product_variant_uid, image.url) }, { children: _jsx("img", { src: image.url, style: { width: '100%', height: '100%', objectFit: 'cover' } }, void 0) }), void 0))) }), void 0), _jsx("div", { children: _jsx(Button, Object.assign({ sx: {
                                                width: 54,
                                                height: 54,
                                                margin: '1px',
                                                padding: 0,
                                                border: '1px solid',
                                                borderColor: 'transparent',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }, onClick: () => handleDialogOpen(variant.product_variant_uid) }, { children: _jsx("span", Object.assign({ style: { fontSize: '24px', color: 'blue' } }, { children: "+" }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Box, Object.assign({ sx: { padding: '1rem', backgroundColor: 'white', paddingTop: '0', height: '105px' } }, { children: _jsx("div", Object.assign({ className: "product-selector custom-scroll" }, { children: _jsx("div", Object.assign({ className: "product-item" }, { children: variant.variant_value_inst.map((variantValue) => (_jsxs(Box, Object.assign({ sx: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '0.5rem',
                                            marginTop: '0.5rem',
                                            overflowX: 'auto',
                                            '&:hover': {
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                transition: 'box-shadow 0.1s ease-in-out',
                                            },
                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "body2", sx: { fontWeight: 500, fontSize: '15px', marginBottom: '3px', textTransform: 'capitalize' } }, { children: variantValue.variant_value_of[0].variant_type_name }), void 0), variantValue.variant_value_of[0].variant_type === 'color' ? (_jsx(Box, { sx: {
                                                    width: 35,
                                                    height: 35,
                                                    margin: '1px',
                                                    backgroundColor: variantValue.variant_value[0].color,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    borderRadius: '3%',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                        transform: 'scale(1.05)',
                                                        transition: 'all 0.3s ease-in-out',
                                                    },
                                                } }, void 0)) : variantValue.variant_value_of[0].variant_type === 'string' ? (_jsx(Box, Object.assign({ sx: {
                                                    minWidth: 35,
                                                    height: 35,
                                                    margin: '1px',
                                                    padding: 0,
                                                    backgroundColor: '#E4E4EC',
                                                    display: 'flex',
                                                    borderRadius: '3%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                        transform: 'scale(1.05)',
                                                        transition: 'all 0.3s ease-in-out',
                                                    },
                                                } }, { children: _jsx(Typography, Object.assign({ variant: "body2", sx: {
                                                        fontSize: '12px',
                                                        color: 'black',
                                                        textAlign: 'center',
                                                        wordWrap: 'break-word',
                                                    } }, { children: variantValue.variant_value[0].label }), void 0) }), void 0)) : (_jsx(Box, { sx: {
                                                    width: 35,
                                                    height: 35,
                                                    margin: '1px',
                                                    padding: 0,
                                                    backgroundImage: `url(${variantValue.variant_value[0].image.url})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    display: 'flex',
                                                    borderRadius: '3%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                        transform: 'scale(1.05)',
                                                        transition: 'all 0.3s ease-in-out',
                                                    },
                                                } }, void 0))] }), variantValue.variant_value_of[0].variant_type_name))) }), void 0) }), void 0) }), void 0)] }), void 0) }), variant.product_variant_uid))), _jsxs(Dialog, Object.assign({ open: openDialog, onClose: handleDialogClose, fullWidth: true, maxWidth: "sm" }, { children: [_jsx(DialogTitle, { children: "Add a New Image" }, void 0), _jsx(DialogContent, { children: _jsx(DragDrop, { onImagesUpload: (images) => {
                                console.log(`Uploaded images for variant UID: ${selectedVariantUid}`, images);
                                onReloadProduct(); // Reload product data
                                handleDialogClose(); // Close dialog after upload
                            }, entity: "product_variant", uid: selectedVariantUid }, void 0) }, void 0), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: handleDialogClose, color: "primary" }, { children: "Cancel" }), void 0) }, void 0)] }), void 0), _jsxs(Dialog, Object.assign({ open: variantDeleteConform, onClose: () => setVariantDeleteConform(false), fullWidth: true, maxWidth: "sm" }, { children: [_jsx(DialogTitle, { children: "Delete Product Variant" }, void 0), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: "Are you sure you want to delete ?" }, void 0) }, void 0), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: handleProductVariantDeleteCancel, color: "primary" }, { children: "Cancel" }), void 0), _jsx(Button, Object.assign({ onClick: handleProductVariantDeleteConform, color: "primary" }, { children: "Conform" }), void 0)] }, void 0)] }), void 0)] }), void 0));
};
export default VariantImageSelector;
