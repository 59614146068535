import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import React, { useEffect, useState } from "react";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../../saga/saga-context";
import { useSaga } from "../../../saga/use-saga";
import container from "./style";
import { Trash, Edit } from "react-feather";
import { useSubmit } from "../../../saga/use-submit";
import { NavLink } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { global_org_uid } from "../../../config";
import { toast } from "react-toastify";
const CategoryItem = (props) => {
    const { submit, data, error, status } = useSubmit();
    const { categoryid, categoryname, category, categorydescription, menutextstyle, namestyle, titlestyle, textstyle, productstyle } = useStyles("categorydesc", "category", "categoryname", "namestyle", "categorydescription", "menutextstyle", "titlestyle", "textstyle", "productstyle");
    const { product_class_name, image, uid, onDelete, loadPage } = props;
    const [open, setOpen] = useState(false);
    const [delete_uid, setUid] = useState();
    const deleteCat = (uid) => {
        setOpen(true);
        setUid(uid);
    };
    const handleDelete = () => {
        submit(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/${uid}/delete`, { method: "DElETE" });
        setOpen(false);
        setUid('');
    };
    const handleClose = () => {
        setOpen(false);
        setUid('');
    };
    useEffect(() => {
        if (onDelete)
            onDelete();
    }, [data]);
    useEffect(() => {
        if (status === "ok") {
            toast.success("Product Class Deleted Successfully");
            loadPage();
        }
    }, [status]);
    return (_jsxs(Box, Object.assign({ css: category, padding: 2, borderRadius: 2, boxShadow: 2, bgcolor: "#f9f9f9" }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 5 }, { children: image ? (_jsx("img", { src: image.url, alt: "Class", style: {
                                borderRadius: 8,
                                objectFit: 'cover',
                                width: '100%',
                                maxWidth: 400,
                                height: 400,
                            } }, void 0)) : (_jsx(Box, Object.assign({ sx: {
                                borderRadius: 2,
                                bgcolor: '#e0e0e0',
                                width: '100%',
                                maxWidth: 400,
                                height: 400,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '1rem',
                                color: '#757575',
                            } }, { children: "No Image Available" }), void 0)) }), void 0), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 7 }, { children: [_jsx(Typography, Object.assign({ css: titlestyle, variant: "h6" }, { children: "Class Name" }), void 0), _jsx(Typography, Object.assign({ css: textstyle, variant: "body1", gutterBottom: true }, { children: product_class_name }), void 0), _jsxs(Grid, Object.assign({ container: true, spacing: 2, style: { marginTop: 16 } }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Button, Object.assign({ startIcon: _jsx(Trash, {}, void 0), variant: "outlined", color: "error", onClick: () => deleteCat(uid), sx: { textTransform: 'none' } }, { children: "Delete" }), void 0) }), void 0), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(NavLink, Object.assign({ to: `edit/${uid}?name=${product_class_name}&image=${image ? image.url : ""}`, style: { textDecoration: 'none' } }, { children: _jsx(Button, Object.assign({ startIcon: _jsx(Edit, {}, void 0), variant: "contained", color: "primary", sx: { textTransform: 'none' } }, { children: "Edit" }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, Object.assign({ id: "alert-dialog-title" }, { children: "Product Class Delete Confirmation" }), void 0), _jsx(DialogContent, { children: _jsx(DialogContentText, Object.assign({ id: "alert-dialog-description" }, { children: "Are you sure you want to delete?." }), void 0) }, void 0), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: handleClose, color: "primary" }, { children: "Cancel" }), void 0), _jsx(Button, Object.assign({ onClick: handleDelete, color: "primary", autoFocus: true }, { children: "Delete" }), void 0)] }, void 0)] }), void 0)] }), void 0));
};
export const CategoryListInternal = () => {
    const { data, loadUrl } = useSaga(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/list`);
    const { product_classes } = data || {};
    const deleteHandler = (index) => () => {
        // items.splice(index, 1)
        // alert(index)
        // loadUrl(`/trendutube/v1/public/categories/list`)
    };
    const { productstyle } = useStyles("productstyle");
    const loadPage = () => {
        loadUrl(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/list`);
    };
    useEffect(() => {
        console.log("DATA++", data);
    }, [data]);
    const [value, setValue] = useState();
    const onInputChange = (e) => {
        setValue(e.target.value);
        loadUrl(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/list?class_name=${e.target.value}&`);
    };
    return (_jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, sx: { height: '44px' } }, { children: _jsx(TextField, { fullWidth: true, size: "small", variant: "outlined", value: value, placeholder: 'Search', onChange: onInputChange, InputProps: {
                                startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchOutlined, {}, void 0) }), void 0)),
                            } }, void 0) }), void 0) }), void 0), 
                // JSON.stringify(items)
                product_classes &&
                    product_classes.map((item, index) => (_jsx(CategoryItem, Object.assign({}, item, { onDelete: deleteHandler(index), loadPage: loadPage }), item.uid)))] }, void 0) }), void 0));
};
export const ProductClassList = () => (_jsx(SagaProvider, { children: _jsx(CategoryListInternal, {}, void 0) }, void 0));
