import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../saga/saga-context";
import container from "./style";
import { useEffect, useState } from "react";
import { useSubmit } from "../../saga/use-submit";
import { usePagination } from "../../saga/use-pagination";
import { Box, Button, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { global_org_uid } from "../../config";
const textFieldStyles = {
    backgroundColor: "#F6F8F9",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
            maxHeight: '40px',
            maxWidth: '400px',
        },
        "& input": {
            color: "black",
            padding: "10px 12px",
            height: "100%",
            boxSizing: "border-box",
        },
        display: "flex",
        alignItems: "center",
    },
};
const ConfigInternal = (props) => {
    const { submit, error, status } = useSubmit();
    const [addDetails, setAddDetails] = useState(false);
    useEffect(() => {
        if (status === "ok") {
            toast.success("Details added Successfully");
            setAddDetails(false);
            setDialogOpen(false);
            setEditCourier("");
            setUpdatedKeys({});
            loadPage(paging === null || paging === void 0 ? void 0 : paging.page);
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const queryParams = { page_size: 100 };
    const { data: couriers, loadPage, paging } = usePagination(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/couriers`, { queryParams });
    const [deliveryDetails, setDeliveryDetails] = useState({
        name: '',
        keys: [], // List of keys
    });
    const [updatedKeys, setUpdatedKeys] = useState({});
    // Handler to update key values
    const handleKeyValueChange = (keyName, newValue) => {
        setUpdatedKeys((prev) => (Object.assign(Object.assign({}, prev), { [keyName]: newValue })));
    };
    const handleSaveCourier = (courier) => {
        const hasEmptyValues = Object.values(updatedKeys).some(value => value === "" || value === null || value === undefined);
        if (hasEmptyValues) {
            toast.error("Invalid key exists in the inputs. Please check and update the values.");
        }
        else {
            submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/couriers/${courier.uid}/add-authentication`, { body: { authentication: updatedKeys, authenticated_uid: global_org_uid } });
        }
    };
    const handleChange = (courier) => {
        submit(`/trendutube/v1/config-management/admin/couriers/${courier.available_courier_uid}/update-authentication-status`, { body: { enabled: !courier.enabled } });
    };
    const handleUpdateCourier = (courier) => {
        const hasEmptyValues = Object.values(updatedKeys).some(value => value === "" || value === null || value === undefined);
        if (hasEmptyValues) {
            toast.error("Invalid key exists in the inputs. Please check and update the values.");
        }
        else {
            submit(`/trendutube/v1/config-management/admin/couriers/${courier.uid}/update-authentication`, { body: { authentication: updatedKeys } });
        }
    };
    const [editCourier, setEditCourier] = useState('');
    const handleCancel = () => {
        setUpdatedKeys({});
        setEditCourier("");
    };
    const [dialogOpen, setDialogOpen] = useState(false);
    const [age, setAge] = useState('');
    const [open, setOpen] = useState(false);
    const handleCourierChange = (event) => {
        setAge(event.target.value);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return (_jsxs("div", { children: [couriers && couriers.length > 0 ? _jsxs("div", Object.assign({ style: { width: '100%' } }, { children: [_jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' } }, { children: (couriers === null || couriers === void 0 ? void 0 : couriers.filter((co) => co.unusedCourier).length) > 0 ? _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => setDialogOpen(true), sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Add Courier Service" }), void 0) : null }), void 0), _jsx(Box, Object.assign({ sx: { padding: '20px' } }, { children: couriers && couriers.filter((co) => !co.unusedCourier).map((courier) => (courier.uid !== editCourier ? _jsxs(Box, Object.assign({ sx: { marginBottom: '20px', padding: '20px', backgroundColor: '#f9f9f9' } }, { children: [_jsxs(Typography, Object.assign({ variant: "h6" }, { children: ["Courier Name: ", courier.name] }), void 0), _jsx(Switch, { checked: courier.enabled, onChange: () => handleChange(courier), inputProps: { 'aria-label': 'controlled' }, disabled: courier.uid !== editCourier }, void 0), _jsx(Grid, Object.assign({ container: true, spacing: 2, sx: { marginTop: '10px' } }, { children: courier.keys.map((keyValue, keyIndex) => {
                                        var _a;
                                        return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(TextField, { fullWidth: true, label: `${keyValue.key}`, value: (_a = updatedKeys[keyValue.key_uid]) !== null && _a !== void 0 ? _a : keyValue.value, onChange: (e) => handleKeyValueChange(keyValue.key_uid, e.target.value), variant: "outlined", disabled: courier.uid !== editCourier }, void 0) }), keyIndex));
                                    }) }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => setEditCourier(courier.uid), sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Edit" }), void 0)] }), courier.uid) :
                            _jsxs(Box, Object.assign({ sx: { marginBottom: '20px', padding: '20px', backgroundColor: '#f9f9f9' } }, { children: [_jsxs(Typography, Object.assign({ variant: "h6" }, { children: ["Courier Name: ", courier.name] }), void 0), _jsx(Switch, { checked: courier.enabled, onChange: () => handleChange(courier), inputProps: { 'aria-label': 'controlled' } }, void 0), _jsx(Grid, Object.assign({ container: true, spacing: 2, sx: { marginTop: '10px' } }, { children: courier.keys.map((keyValue, keyIndex) => {
                                            var _a;
                                            return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(TextField, { fullWidth: true, label: `${keyValue.key}`, value: (_a = updatedKeys[keyValue.key_value_uid]) !== null && _a !== void 0 ? _a : keyValue.value, onChange: (e) => handleKeyValueChange(keyValue.key_value_uid, e.target.value), variant: "outlined" }, void 0) }), keyIndex));
                                        }) }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => handleUpdateCourier(courier), sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Update" }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: handleCancel, sx: { margin: '5px', backgroundColor: " #045998" } }, { children: "Cancel" }), void 0)] }), courier.uid))) }), void 0)] }), void 0) : _jsx("p", { children: "Couriers are not available" }, void 0), _jsx(Dialog, Object.assign({ open: dialogOpen, onClose: () => setDialogOpen(false), maxWidth: "sm", fullWidth: true }, { children: _jsxs(Box, Object.assign({ sx: { padding: '20px', backgroundColor: '#f5f5f5' } }, { children: [_jsxs(FormControl, Object.assign({ sx: { marginBottom: '20px', minWidth: 120 }, fullWidth: true }, { children: [_jsx(InputLabel, Object.assign({ id: "demo-controlled-open-select-label" }, { children: "Courier" }), void 0), _jsx(Select, Object.assign({ labelId: "demo-controlled-open-select-label", id: "demo-controlled-open-select", open: open, onClose: handleClose, onOpen: handleOpen, value: age, label: "Courier", onChange: handleCourierChange }, { children: couriers && couriers
                                        .filter((co) => co.unusedCourier)
                                        .map((courier) => (_jsx(MenuItem, Object.assign({ value: courier.uid }, { children: courier.name }), courier.uid))) }), void 0)] }), void 0), couriers && couriers
                            .filter((co) => co.uid === age)
                            .map((courier) => (_jsxs(Box, Object.assign({ sx: {
                                padding: '20px',
                                borderRadius: '8px',
                                marginBottom: '20px',
                            } }, { children: [_jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: courier.keys.map((keyValue, keyIndex) => {
                                        var _a;
                                        return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(TextField, { fullWidth: true, label: keyValue.key, value: (_a = updatedKeys[keyValue.key_uid]) !== null && _a !== void 0 ? _a : keyValue.value, onChange: (e) => handleKeyValueChange(keyValue.key_uid, e.target.value), variant: "outlined" }, void 0) }), keyIndex));
                                    }) }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end', marginTop: '20px' } }, { children: [_jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => handleSaveCourier(courier), sx: {
                                                marginRight: '10px',
                                                backgroundColor: '#045998',
                                                color: '#ffffff',
                                                '&:hover': { backgroundColor: '#03457a' },
                                            } }, { children: "Save" }), void 0), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => setDialogOpen(false), sx: {
                                                color: '#045998',
                                                borderColor: '#045998',
                                                '&:hover': { backgroundColor: '#e5f3fd' },
                                            } }, { children: "Cancel" }), void 0)] }), void 0)] }), courier.uid)))] }), void 0) }), void 0)] }, void 0));
};
export const CouriersServiceDetails = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
