import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportsource @emotion/react */
import { useEffect, useState } from "react";
import { useStyles } from "@exprzn/react-style-lib";
import { Row } from "../../components/Row";
import { Col } from "../../components/Column";
import { toast } from "react-toastify";
import { useRestSubmit } from "../../saga/use-rest-submit";
import { global_org_uid } from "../../config";
import { Box, Button, Divider, Drawer, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DragDrop } from "../../components/DragDrop";
import { HexColorPicker } from "react-colorful";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSaga } from "../../saga/use-saga";
import { useSubmit } from "../../saga/use-submit";
export const VariantTypeAddComponent = () => {
    const onSaveVariantTypeSuccess = () => {
        // if (mode == "edit")
        loadDefaultVariant();
        toast.success("Saved Variant Type");
    };
    const onSaveVariantTypeError = (error) => {
        toast.error(error);
    };
    const saveVariantType = useRestSubmit(onSaveVariantTypeSuccess, onSaveVariantTypeError);
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [affects_pricing, setAffectPricing] = useState(false);
    const { button, inputstyle, } = useStyles("inputstyle", "button");
    const { loadUrl, data, isFetching } = useSaga(``, {}, false);
    const loadDefaultVariant = () => loadUrl(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/list`);
    useEffect(() => {
        loadDefaultVariant();
    }, []);
    const handleRadio = (e) => {
        setType(e.currentTarget.value);
    };
    const variant_types = [
        {
            "uid": "0x16469f6",
            "variant_type_name": "Standard-Colors",
            "variant_type": "color",
            "variant_values": [
                {
                    "color": "#02CE08",
                    "name": "Green",
                    "variant_uid": "0x16469f8",
                    "variant_type_name": "Standard-Colors"
                },
                {
                    "color": "#1C2AC8",
                    "name": "Blue",
                    "variant_uid": "0x16469fa",
                    "variant_type_name": "Standard-Colors"
                },
                {
                    "color": "#FE0000",
                    "name": "Red",
                    "variant_uid": "0x16469fc",
                    "variant_type_name": "Standard-Colors"
                }
            ]
        },
        {
            "uid": "0x1646a02",
            "variant_type_name": "Fabric Type",
            "variant_type": "image",
            "variant_values": [
                {
                    "name": "Cotton",
                    "variant_uid": "0x1646a01",
                    "variant_type_name": "Fabric Type",
                    "image": {
                        "uid": "0x16468db",
                        "url": "http://api-dev.trendutube.com/image-service/images/retrieve/0x16468db"
                    }
                },
                {
                    "name": "Denim",
                    "variant_uid": "0x1646a03",
                    "variant_type_name": "Fabric Type",
                    "image": {
                        "uid": "0x16468dd",
                        "url": "http://api-dev.trendutube.com/image-service/images/retrieve/0x16468dd"
                    }
                },
                {
                    "name": "Silks",
                    "variant_uid": "0x1646a04",
                    "variant_type_name": "Fabric Type",
                    "image": {
                        "uid": "0x16468dc",
                        "url": "http://api-dev.trendutube.com/image-service/images/retrieve/0x16468dc"
                    }
                }
            ]
        },
        {
            "uid": "0x1646a06",
            "variant_type_name": "Fabric-Type",
            "variant_type": "image",
            "variant_values": [
                {
                    "name": "Cotton",
                    "variant_uid": "0x1646a05",
                    "variant_type_name": "Fabric-Type",
                    "image": {
                        "uid": "0x16468db",
                        "url": "http://api-dev.trendutube.com/image-service/images/retrieve/0x16468db"
                    }
                },
                {
                    "name": "Denim",
                    "variant_uid": "0x1646a07",
                    "variant_type_name": "Fabric-Type",
                    "image": {
                        "uid": "0x16468dd",
                        "url": "http://api-dev.trendutube.com/image-service/images/retrieve/0x16468dd"
                    }
                }
            ]
        }
    ];
    const loadFunction = () => {
        loadDefaultVariant();
    };
    const [variantTypeName, setVariantTypeName] = useState();
    const [variantType, setVariantType] = useState();
    const [variantTypeAdd, setDefaultVarintTypeAdd] = useState(false);
    function addVariantType() {
        if (!variantTypeName || (variantTypeName && variantTypeName.length < 0)) {
            toast.error("Invalid Variant Type Name ");
        }
        else if (variantTypeName.includes(" ")) {
            toast.error("The variant Type Name contains a space.");
        }
        else if (!variantType || (variantType && variantType.length < 0)) {
            toast.error("Invalid Variant Type ");
        }
        else {
            saveVariantType(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/add`, { body: { name: variantTypeName, type: variantType, affects_pricing: true, values: [] } });
        }
    }
    const { containerstyle, action_btn_ms, disabled_btn_ms, titlestyle } = useStyles("containerstyle", "action_btn_ms", "disabled_btn_ms", "titlestyle");
    return _jsxs("div", { children: [_jsxs(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f9f9f9',
                    minHeight: '100vh',
                    padding: '1rem',
                } }, { children: [_jsxs(Box, Object.assign({ sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '800px',
                            marginBottom: '1rem',
                            padding: '0 1rem',
                        } }, { children: [_jsx(Typography, Object.assign({ variant: "h5", fontWeight: "bold" }, { children: "Variant Types" }), void 0), _jsx(Button, Object.assign({ variant: "contained", color: "primary", onClick: () => setDefaultVarintTypeAdd(true) }, { children: "Add Variant Type" }), void 0)] }), void 0), _jsx(Box, Object.assign({ sx: {
                            width: '100%',
                            maxWidth: '800px',
                            backgroundColor: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            padding: '2rem',
                        } }, { children: data ? (_jsx(VariantTypesComponent, { variant_types: data, mode: "edit", loadFunction: loadFunction }, void 0)) : (_jsx(Typography, Object.assign({ textAlign: "center", color: "text.secondary" }, { children: "No variant types available." }), void 0)) }), void 0)] }), void 0), _jsxs(Drawer, Object.assign({ anchor: 'right', open: variantTypeAdd, PaperProps: {
                    sx: { maxWidth: '350px', padding: '1rem' },
                }, onClose: () => setDefaultVarintTypeAdd(true) }, { children: [_jsx(Box, Object.assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '2rem',
                            alignItems: 'center',
                            gap: 1,
                        } }, { children: _jsxs(Button, Object.assign({ onClick: () => setDefaultVarintTypeAdd(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant Type" }), void 0)] }), void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Variant Type Name" }), void 0), _jsx(TextField, { size: "small", name: "variantTypeName", value: variantTypeName, fullWidth: true, id: "fullWidth", onChange: (e) => setVariantTypeName(e.target.value) }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Variant Type Name" }), void 0), _jsx(Box, Object.assign({ sx: { minWidth: 120, marginBottom: '10px' } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, size: "small" }, { children: _jsxs(Select, Object.assign({ labelId: "demo-simple-select-label", id: "demo-simple-select", value: variantType, onChange: (e) => setVariantType(e.target.value) }, { children: [_jsx(MenuItem, Object.assign({ value: "color" }, { children: "Color" }), void 0), _jsx(MenuItem, Object.assign({ value: "string" }, { children: "String" }), void 0), _jsx(MenuItem, Object.assign({ value: "image" }, { children: "Image" }), void 0)] }), void 0) }), void 0) }), void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx("button", Object.assign({ type: "button", css: action_btn_ms, onClick: addVariantType }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }, void 0);
};
export const VariantTypesComponent = (props) => {
    const { variant_types = [], mode, loadFunction } = props;
    return _jsx("div", { children: variant_types.map((vType, index) => (_jsxs(_Fragment, { children: [_jsx(VariantTypeComponent, { variantType: vType, index: index, loadFunction: loadFunction }, void 0), index != variant_types.length - 1 && _jsx(Divider, { variant: "middle" }, void 0)] }, void 0))) }, void 0);
};
export const VariantTypeComponent = (props) => {
    const { variantType, addVariant, index, loadFunction } = props;
    return _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { children: [variantType.variant_type == "color" ?
                        _jsx(ColorVariantType, { variantType: variantType, loadFunction: loadFunction }, void 0) : null, variantType.variant_type == "string" ?
                        _jsx(StringVariantType, { variantType: variantType, loadFunction: loadFunction }, void 0) : null, variantType.variant_type == "image" ?
                        _jsx(ImageVariantType, { variantType: variantType, loadFunction: loadFunction }, void 0) : null] }, void 0) }, void 0) }, void 0);
};
export const ColorVariantType = (props) => {
    var _a;
    const { variantType, loadFunction } = props;
    const { submit, data, error, status } = useSubmit();
    const [dialogVisible, showDialog] = useState(false);
    const handleDelete = () => {
        submit(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/${variantType.uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Type Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    function onSelect(name, color) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "color",
                values: [{ name, value: { color } }]
            }
        });
    }
    return _jsxs(Box, Object.assign({ sx: {
            border: "nones",
            padding: "0.5em",
            paddingLeft: '0',
            borderRadius: ".5em",
            marginTop: "0.5rem",
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "10px",
                            } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                        fontWeight: 600,
                                        fontSize: "18px",
                                    } }, { children: variantType.variant_type_name }), void 0), _jsx(Box, { children: _jsx(Button, Object.assign({ variant: "text", size: "small", color: "error", onClick: () => handleDelete() }, { children: "Delete" }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1
                            } }, { children: [(_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(ColorTypeValue, { variantTypeValue: vt, loadFunction: loadFunction, variant_uid: variantType.uid }, void 0)), _jsx(Button, Object.assign({ sx: {
                                        minWidth: 40,
                                        height: 40,
                                        marginLeft: "8px",
                                        border: "1px solid",
                                        borderColor: "transparent",
                                        backgroundColor: "#EFF5FB",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }, onClick: () => showDialog(true) }, { children: _jsx("span", Object.assign({ style: { fontSize: "24px", color: "blue" } }, { children: "+" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), dialogVisible && _jsx(ColorAdd, { close: showDialog, onSelect: onSelect, color: "#fff" }, void 0)] }), void 0);
};
export const StringVariantType = (props) => {
    var _a;
    const { variantType, loadFunction } = props;
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/${variantType.uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Type Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    function onSelect(name) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "string",
                values: [{ name, value: { label: name } }]
            }
        });
    }
    return _jsxs(Box, Object.assign({ sx: {
            border: "none",
            padding: "0.5em",
            paddingLeft: '0',
            borderRadius: ".5em",
            marginTop: "1rem",
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "10px",
                            } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                        fontWeight: 600,
                                        fontSize: "18px",
                                    } }, { children: variantType.variant_type_name }), void 0), _jsx(Box, { children: _jsx(Button, Object.assign({ variant: "text", size: "small", color: "error", onClick: () => handleDelete() }, { children: "Delete" }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                            } }, { children: [(_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map((vt, index) => (_jsx(StringTypeValue, { variantTypeValue: vt, loadFunction: loadFunction, variant_uid: variantType.uid }, index))), _jsx(Button, Object.assign({ sx: {
                                        minWidth: 40,
                                        height: 40,
                                        marginLeft: "8px",
                                        border: "1px solid",
                                        borderColor: "transparent",
                                        backgroundColor: "#EFF5FB",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }, onClick: () => showDialog(true) }, { children: _jsx("span", Object.assign({ style: { fontSize: "24px", color: "blue" } }, { children: "+" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), dialogVisible && _jsx(StringAdd, { close: showDialog, onSelect: onSelect, value: "" }, void 0)] }), void 0);
};
export const ImageVariantType = (props) => {
    var _a;
    const { variantType, loadFunction } = props;
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/${variantType.uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Type Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    function onSelect(name, images) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "image",
                values: [{ name, value: { uid: images[0].uid } }]
            }
        });
    }
    return _jsxs(Box, Object.assign({ sx: {
            padding: "0.5em",
            paddingLeft: '0',
            borderRadius: ".5em",
            marginTop: "1rem",
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "10px",
                            } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                        fontWeight: 600,
                                        fontSize: "18px",
                                    } }, { children: variantType.variant_type_name }), void 0), _jsx(Box, { children: _jsx(Button, Object.assign({ variant: "text", size: "small", color: "error", onClick: () => handleDelete() }, { children: "Delete" }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                            } }, { children: [(_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(ImageTypeValue, { variantTypeValue: vt, loadFunction: loadFunction, variant_uid: variantType.uid }, void 0)), _jsx(Button, Object.assign({ sx: {
                                        minWidth: 40,
                                        height: 40,
                                        marginLeft: "8px",
                                        border: "1px solid",
                                        borderColor: "transparent",
                                        backgroundColor: "#EFF5FB",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }, onClick: () => showDialog(true) }, { children: _jsx("span", Object.assign({ style: { fontSize: "24px", color: "blue" } }, { children: "+" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), dialogVisible && _jsx(ImageAdd, { close: showDialog, onSelect: onSelect }, void 0)] }), void 0);
};
export const ColorTypeValue = (props) => {
    const { variantTypeValue, loadFunction, variant_uid: variant_type_uid } = props;
    const { color, name, variant_uid } = variantTypeValue;
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/${variant_type_uid}/variant-values/${variant_uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Value Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    return _jsxs(Box, Object.assign({ sx: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            "&:hover .delete-button": {
                opacity: 1, // Make the delete button visible on hover
            },
        } }, { children: [_jsx(Button, Object.assign({ onClick: handleDelete, className: "delete-button" // Add a class for targeting
                , sx: {
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    minWidth: "15px",
                    minHeight: "15px",
                    padding: 0,
                    backgroundColor: "#ff6961",
                    color: "#fff",
                    borderRadius: "50%",
                    fontSize: "10px",
                    fontWeight: "bold",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                    "&:hover": {
                        backgroundColor: "#ff5c5c",
                    },
                } }, { children: "\u00D7" }), void 0), _jsx(Box, { sx: {
                    width: "40px",
                    height: "40px",
                    backgroundColor: color,
                    border: "1px solid #ccc",
                    cursor: "pointer",
                } }, void 0), _jsx(Typography, Object.assign({ sx: {
                    fontSize: "14px",
                    textAlign: "center", // Ensures the text aligns properly
                } }, { children: name }), void 0)] }), void 0);
};
export const ImageTypeValue = (props) => {
    const { variantTypeValue, loadFunction, variant_uid: variant_type_uid } = props;
    const { color, name, image, variant_uid } = variantTypeValue;
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/${variant_type_uid}/variant-values/${variant_uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Value Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    return _jsxs(Box, Object.assign({ sx: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            "&:hover .delete-button": {
                opacity: 1, // Make the delete button visible on hover
            },
        } }, { children: [_jsx(Button, Object.assign({ onClick: handleDelete, className: "delete-button" // Add a class for targeting
                , sx: {
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    minWidth: "15px",
                    minHeight: "15px",
                    padding: 0,
                    backgroundColor: "#ff6961",
                    color: "#fff",
                    borderRadius: "50%",
                    fontSize: "10px",
                    fontWeight: "bold",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                    "&:hover": {
                        backgroundColor: "#ff5c5c",
                    },
                } }, { children: "\u00D7" }), void 0), _jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "4px", // Adds spacing between the box and the name
                } }, { children: [_jsx(Box, { sx: {
                            width: "40px",
                            height: "40px",
                            backgroundImage: `url(${image.url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            border: "1px solid #ccc",
                            cursor: "pointer",
                        } }, void 0), _jsx(Typography, Object.assign({ sx: {
                            fontSize: "14px",
                            textAlign: "center", // Ensures the text aligns properly
                        } }, { children: name }), void 0)] }), void 0)] }), void 0);
};
export const StringTypeValue = (props) => {
    const { variantTypeValue, loadFunction, variant_uid: variant_type_uid } = props;
    const { name, variant_uid } = variantTypeValue;
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/variant-types/${variant_type_uid}/variant-values/${variant_uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Value Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    return _jsxs(Box, Object.assign({ sx: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            "&:hover .delete-button": {
                opacity: 1, // Make the delete button visible on hover
            },
        } }, { children: [_jsx(Button, Object.assign({ onClick: handleDelete, className: "delete-button" // Add a class for targeting
                , sx: {
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    minWidth: "15px",
                    minHeight: "15px",
                    padding: 0,
                    backgroundColor: "#ff6961",
                    color: "#fff",
                    borderRadius: "50%",
                    fontSize: "10px",
                    fontWeight: "bold",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                    "&:hover": {
                        backgroundColor: "#ff5c5c",
                    },
                } }, { children: "\u00D7" }), void 0), _jsx(Box, Object.assign({ sx: {
                    minWidth: '40px',
                    height: '40px',
                    backgroundColor: '#E4E4EC',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #E4E4EC',
                    cursor: 'pointer',
                } }, { children: _jsx(Typography, Object.assign({ sx: {
                        fontSize: '12px',
                        textAlign: 'center',
                        color: 'black',
                    } }, { children: name }), void 0) }), void 0)] }), void 0);
};
export const ColorAdd = (props) => {
    const { close, onSelect, color } = props;
    const [selColor, setColor] = useState(color);
    const [name, setName] = useState();
    function selectColor(cl) {
        // onSelect(cl)
        setColor(cl);
    }
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else if (!selColor)
            alert("Please enter a valid name");
        else
            onSelect(name, selColor);
    }
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: true, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => close(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '1rem',
                    alignItems: 'center',
                    gap: 1,
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => close(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant" }), void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ style: { padding: '1rem' } }, { children: [_jsx("div", { children: "Color Name" }, void 0), _jsx(TextField, { size: "small", sx: { marginBottom: '1rem' }, name: "variantTypeName", value: name, fullWidth: true, id: "fullWidth", onChange: (e) => setName(e.target.value) }, void 0), _jsx(HexColorPicker, { color: color, onChange: selectColor, style: { width: '100%' } }, void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' } }, { children: _jsx(Button, Object.assign({ fullWidth: true, variant: "contained", onClick: () => save(), sx: { textTransform: 'none', backgroundColor: '#045998', boxShadow: 'none', color: 'white' } }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export const StringAdd = (props) => {
    const { close, onSelect, value } = props;
    const [name, setName] = useState();
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else
            onSelect(name);
    }
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: true, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => close(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1rem',
                    alignItems: 'center',
                    gap: 1,
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => close(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant" }), void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ style: { padding: '1rem' } }, { children: [_jsx("div", { children: "Variant Value" }, void 0), _jsx(TextField, { size: "small", sx: { marginBottom: '1rem' }, name: "variantTypeName", value: name, fullWidth: true, id: "fullWidth", onChange: (e) => setName(e.target.value) }, void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, Object.assign({ fullWidth: true, variant: "contained", onClick: () => save(), sx: { textTransform: 'none', backgroundColor: '#045998', boxShadow: 'none', color: 'white' } }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export const ImageAdd = (props) => {
    const { close, onSelect } = props;
    const [name, setName] = useState();
    const [images, setImages] = useState([]);
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else if (images.length <= 0)
            alert("Upload an image");
        else
            onSelect(name, images);
    }
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: true, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => close(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '1rem',
                    alignItems: 'center',
                    gap: 1,
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => close(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant" }), void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ style: { padding: '1rem' } }, { children: [_jsx("div", { children: "Enter a value to desccribe the variant" }, void 0), _jsx(TextField, { size: "small", sx: { marginBottom: '1rem' }, name: "variantTypeName", value: name, fullWidth: true, id: "fullWidth", onChange: (e) => setName(e.target.value) }, void 0), _jsx(DragDrop, { onImagesUpload: (images) => { setImages(images); }, entity: "variant" }, void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, Object.assign({ fullWidth: true, variant: "contained", onClick: () => save(), sx: { textTransform: 'none', backgroundColor: '#045998', boxShadow: 'none', color: 'white' } }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }), void 0);
};
