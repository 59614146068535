import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
import { HexColorPicker } from "react-colorful";
import { DragDrop } from "../../../components/DragDrop";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { toast } from "react-toastify";
import ReactSlidingPane from "react-sliding-pane";
import { VariantTypeAddComponent } from "./variant-type-add-view";
import { Box, Button, Drawer, Grid, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSubmit } from "../../../saga/use-submit";
export const VariantTypesComponent = (props) => {
    const { variant_types = [], mode, loadFunction, product_uid } = props;
    return _jsx("div", { children: variant_types.map((vType, index) => (_jsxs(_Fragment, { children: [_jsx(VariantTypeComponent, { variantType: vType, index: index, loadFunction: loadFunction, product_uid: product_uid }, void 0), index != variant_types.length - 1 && _jsx(Divider, { variant: "middle" }, void 0)] }, void 0))) }, void 0);
};
export const VariantTypeComponent = (props) => {
    const { variantType, addVariant, index, loadFunction, product_uid } = props;
    return _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { children: [variantType.variant_type == "color" ?
                        _jsx(ColorVariantType, { variantType: variantType, loadFunction: loadFunction, product_uid: product_uid }, void 0) : null, variantType.variant_type == "string" ?
                        _jsx(StringVariantType, { variantType: variantType, loadFunction: loadFunction, product_uid: product_uid }, void 0) : null, variantType.variant_type == "image" ?
                        _jsx(ImageVariantType, { variantType: variantType, loadFunction: loadFunction, product_uid: product_uid }, void 0) : null] }, void 0) }, void 0) }, void 0);
};
export const ColorVariantType = (props) => {
    var _a;
    const { variantType, loadFunction, product_uid } = props;
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/product-catalog/products/${product_uid}/variant-types/${variantType.uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Type Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    function onSelect(name, color) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "color",
                values: [{ name, value: { color } }]
            }
        });
    }
    return _jsxs(Box, Object.assign({ sx: {
            border: "nones",
            padding: "0.5em",
            paddingLeft: '0',
            borderRadius: ".5em",
            marginTop: "0.5rem",
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    paddingBottom: '10px',
                                } }, { children: variantType.variant_type_name }), void 0), _jsx(IconButton, Object.assign({ color: "error", onClick: handleDelete }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0)] }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1
                            } }, { children: [(_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(ColorTypeValue, { variantTypeValue: vt }, void 0)), _jsx(Button, Object.assign({ sx: {
                                        minWidth: 40,
                                        height: 40,
                                        marginLeft: "8px",
                                        border: "1px solid",
                                        borderColor: "transparent",
                                        backgroundColor: "#EFF5FB",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }, onClick: () => showDialog(true) }, { children: _jsx("span", Object.assign({ style: { fontSize: "24px", color: "blue" } }, { children: "+" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), dialogVisible && _jsx(ColorAdd, { close: showDialog, onSelect: onSelect, color: "#fff" }, void 0)] }), void 0);
};
export const StringVariantType = (props) => {
    var _a;
    const { variantType, loadFunction, product_uid } = props;
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/product-catalog/products/${product_uid}/variant-types/${variantType.uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Type Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    function onSelect(name) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "string",
                values: [{ name, value: { label: name } }]
            }
        });
    }
    return _jsxs(Box, Object.assign({ sx: {
            border: "none",
            padding: "0.5em",
            paddingLeft: '0',
            borderRadius: ".5em",
            marginTop: "1rem",
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    paddingBottom: '10px',
                                } }, { children: variantType.variant_type_name }), void 0), _jsx(IconButton, Object.assign({ color: "error", onClick: handleDelete }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0)] }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                            } }, { children: [(_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map((vt, index) => (_jsx(StringTypeValue, { variantTypeValue: vt }, index))), _jsx(Button, Object.assign({ sx: {
                                        minWidth: 40,
                                        height: 40,
                                        marginLeft: "8px",
                                        border: "1px solid",
                                        borderColor: "transparent",
                                        backgroundColor: "#EFF5FB",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }, onClick: () => showDialog(true) }, { children: _jsx("span", Object.assign({ style: { fontSize: "24px", color: "blue" } }, { children: "+" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), dialogVisible && _jsx(StringAdd, { close: showDialog, onSelect: onSelect, value: "" }, void 0)] }), void 0);
};
export const ImageVariantType = (props) => {
    var _a;
    const { variantType, loadFunction, product_uid } = props;
    const { submit, data, error, status } = useSubmit();
    const handleDelete = () => {
        submit(`/trendutube/v1/product-catalog/products/${product_uid}/variant-types/${variantType.uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (status == "ok") {
            toast.success("Variant Type Deleted");
            loadFunction();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    function onSelect(name, images) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "image",
                values: [{ name, value: { uid: images[0].uid } }]
            }
        });
    }
    return _jsxs(Box, Object.assign({ sx: {
            padding: "0.5em",
            paddingLeft: '0',
            borderRadius: ".5em",
            marginTop: "1rem",
        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    paddingBottom: '10px',
                                } }, { children: variantType.variant_type_name }), void 0), _jsx(IconButton, Object.assign({ color: "error", onClick: handleDelete }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0)] }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                            } }, { children: [(_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(ImageTypeValue, { variantTypeValue: vt }, void 0)), _jsx(Button, Object.assign({ sx: {
                                        minWidth: 40,
                                        height: 40,
                                        marginLeft: "8px",
                                        border: "1px solid",
                                        borderColor: "transparent",
                                        backgroundColor: "#EFF5FB",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }, onClick: () => showDialog(true) }, { children: _jsx("span", Object.assign({ style: { fontSize: "24px", color: "blue" } }, { children: "+" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), dialogVisible && _jsx(ImageAdd, { close: showDialog, onSelect: onSelect }, void 0)] }), void 0);
};
export const ColorTypeValue = (props) => {
    const { variantTypeValue } = props;
    const { color, name } = variantTypeValue;
    return _jsxs(Box, Object.assign({ sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
        } }, { children: [_jsx(Box, { sx: {
                    width: "40px",
                    height: "40px",
                    backgroundColor: color,
                    border: "1px solid #ccc",
                    cursor: "pointer",
                } }, void 0), _jsx(Typography, Object.assign({ sx: {
                    fontSize: "14px",
                    textAlign: "center",
                } }, { children: name }), void 0)] }), void 0);
};
export const ImageTypeValue = (props) => {
    const { variantTypeValue } = props;
    const { color, name, image } = variantTypeValue;
    return _jsxs(Box, Object.assign({ sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
        } }, { children: [_jsx(Box, { sx: {
                    width: "40px",
                    height: "40px",
                    backgroundImage: `url(${image.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                } }, void 0), _jsx(Typography, Object.assign({ sx: {
                    fontSize: "14px",
                    textAlign: "center",
                } }, { children: name }), void 0)] }), void 0);
};
export const StringTypeValue = (props) => {
    const { variantTypeValue } = props;
    const { name } = variantTypeValue;
    return _jsx(Box, Object.assign({ sx: {
            minWidth: '40px',
            height: '40px',
            backgroundColor: '#E4E4EC',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #E4E4EC',
            cursor: 'pointer',
        } }, { children: _jsx(Typography, Object.assign({ sx: {
                fontSize: '12px',
                textAlign: 'center',
                color: 'black',
            } }, { children: name }), void 0) }), void 0);
};
export const ColorAdd = (props) => {
    const { close, onSelect, color } = props;
    const [selColor, setColor] = useState(color);
    const [name, setName] = useState();
    function selectColor(cl) {
        // onSelect(cl)
        setColor(cl);
    }
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else if (!selColor)
            alert("Please enter a valid name");
        else
            onSelect(name, selColor);
    }
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: true, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => close(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px',
                    marginBottom: '1rem',
                    alignItems: 'center',
                    gap: 1,
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => close(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant" }), void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ style: { padding: '1rem' } }, { children: [_jsx("div", { children: "Color Name" }, void 0), _jsx(TextField, { size: "small", name: "variantTypeName", sx: { marginBottom: '1rem', marginTop: '0.4rem' }, value: name, fullWidth: true, id: "fullWidth", onChange: (e) => setName(e.target.value) }, void 0), _jsx(HexColorPicker, { color: color, onChange: selectColor, style: { width: '100%' } }, void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => save(), sx: { textTransform: 'none', backgroundColor: '#045998', boxShadow: 'none', color: 'white' } }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export const StringAdd = (props) => {
    const { close, onSelect, value } = props;
    const [name, setName] = useState();
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else
            onSelect(name);
    }
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: true, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => close(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    marginBottom: '1rem',
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => close(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant" }), void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ style: { padding: '1rem' } }, { children: [_jsx("div", { children: "Variant Value" }, void 0), _jsx(TextField, { size: "small", name: "variantTypeName", sx: { marginBottom: '1rem', marginTop: '0.4rem' }, value: name, fullWidth: true, id: "fullWidth", onChange: (e) => setName(e.target.value) }, void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => save(), sx: { textTransform: 'none', backgroundColor: '#045998', boxShadow: 'none', color: 'white' } }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export const ImageAdd = (props) => {
    const { close, onSelect } = props;
    const [name, setName] = useState();
    const [images, setImages] = useState([]);
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else if (images.length <= 0)
            alert("Upload an image");
        else
            onSelect(name, images);
    }
    return _jsxs(Drawer, Object.assign({ anchor: 'right', open: true, PaperProps: {
            sx: { maxWidth: '350px', minWidth: '350px', padding: '1rem' },
        }, onClose: () => close(false) }, { children: [_jsx(Box, Object.assign({ sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '1rem',
                    gap: 1,
                } }, { children: _jsxs(Button, Object.assign({ onClick: () => close(false), sx: { textTransform: 'none', margin: 0, padding: 0 } }, { children: [_jsx(ArrowBackIosIcon, { sx: { color: ' #045998' } }, void 0), _jsx(Typography, Object.assign({ sx: { color: ' #045998', fontWeight: 700 } }, { children: "Add New Variant" }), void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ style: { padding: '1rem' } }, { children: [_jsx("div", { children: "Enter a value to desccribe the variant" }, void 0), _jsx(TextField, { size: "small", name: "variantTypeName", value: name, fullWidth: true, id: "fullWidth", onChange: (e) => setName(e.target.value) }, void 0), _jsx(DragDrop, { onImagesUpload: (images) => { setImages(images); }, entity: "variant" }, void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => save(), sx: { textTransform: 'none', backgroundColor: '#045998', boxShadow: 'none', color: 'white' } }, { children: "Save" }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export const VariantTypeAdd = (props) => {
    const { isOpen, onClose, addVaraintType } = props;
    const isSmallScreen = useMediaQuery("(max-width:900px)");
    return _jsx("div", { children: isOpen ?
            _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Variant Type", subtitle: "", width: isSmallScreen ? "90%" : "30%", onRequestClose: () => {
                    onClose(false);
                } }, { children: _jsx("div", { children: _jsx(VariantTypeAddComponent, { addVariantType: addVaraintType }, void 0) }, void 0) }), void 0) : null }, void 0);
};
