import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../saga/saga-context";
import { useSaga } from "../../saga/use-saga";
import { useAuth } from "../../use-auth";
import container from "./style";
import { useEffect, useState } from "react";
import { useSubmit } from "../../saga/use-submit";
import { usePagination } from "../../saga/use-pagination";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { toast } from "react-toastify";
import { global_org_uid } from "../../config";
const DropdownCell = ({ params, handlePageChange, deliveryCodes }) => {
    const { submit, error, status } = useSubmit();
    const [valueUpdated, setValueUpdated] = useState(false);
    const [updatedUid, setUpdatedUid] = useState("");
    const [selectedValues, setSelectedValues] = useState(params.row.available_delivery_modes || []);
    const handleChange = (event, value) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((item) => item != value));
        }
        else {
            setSelectedValues([...selectedValues, value]);
        }
        setValueUpdated(true);
        setUpdatedUid(params.row.uid);
    };
    useEffect(() => {
        if (status === "ok") {
            toast.success("Pin Code Details Updated");
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const handleUpdate = () => {
        submit(`/trendutube/v1/config-management/admin/pin-code-details/update/${params.row.uid}`, { body: { available_delivery_modes: selectedValues } });
        setValueUpdated(false);
        setUpdatedUid("");
    };
    const handleCancel = () => {
        setValueUpdated(false);
        setUpdatedUid("");
        setSelectedValues(params.row.available_delivery_modes || []);
    };
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", justifyContent: "left", height: "100%", overflow: "visible" }, { children: [_jsx(FormGroup, { children: deliveryCodes.map((item) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.includes(item), onChange: (event) => handleChange(event, item) }, void 0), label: item, value: item }, item))) }, void 0), updatedUid === params.row.uid ? _jsx(Box, Object.assign({ mt: 1, display: "flex", justifyContent: "center" }, { children: valueUpdated ?
                    _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "contained", disableElevation: true, sx: { margin: '5px' }, onClick: handleUpdate }, { children: "Update" }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, sx: { margin: '5px' }, onClick: handleCancel }, { children: "Cancel" }), void 0)] }, void 0) : null }), void 0) : null] }), void 0));
};
const CustomPagination = ({ page, hasNext, onPageChange }) => {
    const handleBack = () => {
        if (page > 0) {
            onPageChange(page - 1);
        }
    };
    const handleForward = () => {
        if (hasNext) {
            onPageChange(page + 1);
        }
    };
    return (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }, { children: [_jsx(IconButton, Object.assign({ onClick: handleBack, disabled: page === 0 }, { children: _jsx(ArrowBackIcon, {}, void 0) }), void 0), _jsx(Box, Object.assign({ mx: 2 }, { children: `Page ${page}` }), void 0), _jsx(IconButton, Object.assign({ onClick: handleForward, disabled: !hasNext }, { children: _jsx(ArrowForwardIcon, {}, void 0) }), void 0)] }), void 0));
};
const ConfigInternal = (props) => {
    const { submit, error, status } = useSubmit();
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { data: deliveryModes, loadUrl: loadDeliveryModeUrl } = useSaga(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/list`, { headers });
    const [addDetails, setAddDetails] = useState(false);
    const [deliveryCodes, setDeliveryCodes] = useState([]);
    const [deliveryDetails, setDeliveryDetails] = useState({ pin_code: '', state: '', district: '', available_delivery_modes: [], circle_name: '' });
    useEffect(() => {
        if (deliveryModes && deliveryModes.length > 0) {
            const deliveryCodes = deliveryModes.map((m) => m.delivery_code);
            setDeliveryCodes(deliveryCodes);
        }
    }, [deliveryModes]);
    useEffect(() => {
        if (status === "ok") {
            toast.success("Details added Successfully");
            setAddDetails(false);
            loadPage(paging === null || paging === void 0 ? void 0 : paging.page);
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const queryParams = { page_size: 10 };
    const { data, loadPage, paging } = usePagination("/trendutube/v1/config-management/admin/pin-code-details", { queryParams });
    const handlePageChange = (newPage) => {
        loadPage(newPage);
    };
    const columns = [
        { field: 'pin_code', headerName: 'Pin Code', flex: 0.1 },
        { field: 'state', headerName: 'State', flex: 0.1 },
        { field: 'district', headerName: 'District', flex: 0.2 },
        { field: 'circle_name', headerName: 'Circle', flex: 0.2 },
        {
            field: 'delivery_flag', headerName: 'Delivery Mode', flex: 0.4, align: 'center', renderCell: (params) => _jsx(DropdownCell, { params: params, handlePageChange: handlePageChange, deliveryCodes: deliveryCodes }, void 0)
        },
    ];
    const handleChange = (event) => {
        setDeliveryDetails(Object.assign(Object.assign({}, deliveryDetails), { [event.target.name]: event.target.value }));
    };
    const handleSubmit = () => {
        const { pin_code, state, available_delivery_modes, circle_name, district } = deliveryDetails;
        if (pin_code && pin_code.length < 0) {
            toast.error("Pin Code is empty");
        }
        else if (state && state.length < 0) {
            toast.error("state is empty");
        }
        else if (circle_name && circle_name.length < 0) {
            toast.error("circle_name is empty");
        }
        else if (district && district.length < 0) {
            toast.error("district is empty");
        }
        else {
            submit(`/trendutube/v1/config-management/admin/${global_org_uid}/pin-code-details/add`, { body: Object.assign({}, deliveryDetails) });
        }
    };
    const handleChangeModes = (event, value) => {
        const { available_delivery_modes } = deliveryDetails;
        let updatedModes = [];
        if (available_delivery_modes.includes(value)) {
            updatedModes = available_delivery_modes.filter((item) => item != value);
        }
        else {
            updatedModes = [...available_delivery_modes, value];
        }
        setDeliveryDetails(Object.assign(Object.assign({}, deliveryDetails), { available_delivery_modes: updatedModes }));
    };
    return (_jsx("div", { children: deliveryModes ? _jsxs("div", Object.assign({ style: { width: '100%' } }, { children: [addDetails ? _jsx(_Fragment, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6, md: 3 }, { children: _jsx(TextField, { fullWidth: true, label: "pin code", name: "pin_code", id: "fullWidth", value: deliveryDetails.pin_code, onChange: handleChange }, void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 6, md: 3 }, { children: _jsx(TextField, { fullWidth: true, label: "state", name: "state", id: "fullWidth", value: deliveryDetails.state, onChange: handleChange }, void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 6, md: 3 }, { children: _jsx(TextField, { fullWidth: true, label: "district", name: "district", id: "fullWidth", value: deliveryDetails.district, onChange: handleChange }, void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 6, md: 3 }, { children: _jsx(TextField, { fullWidth: true, label: "circle_name", name: "circle_name", id: "fullWidth", value: deliveryDetails.circle_name, onChange: handleChange }, void 0) }), void 0), _jsxs(Grid, Object.assign({ item: true, xs: 6, md: 3 }, { children: ["Delivery Modes : ", _jsx(FormGroup, Object.assign({ row: true }, { children: deliveryCodes.map((item) => {
                                            var _a;
                                            return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: (_a = deliveryDetails.available_delivery_modes) === null || _a === void 0 ? void 0 : _a.includes(item), onChange: (event) => handleChangeModes(event, item) }, void 0), label: item, value: item }, item));
                                        }) }), void 0)] }), void 0)] }), void 0) }, void 0) : null, _jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' } }, { children: addDetails ? _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: handleSubmit, sx: { margin: '5px' } }, { children: "submit" }), void 0), _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => setAddDetails(false), sx: { margin: '5px' } }, { children: "Cancel" }), void 0), " "] }, void 0) : _jsx(_Fragment, { children: _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => setAddDetails(!addDetails), sx: { margin: '5px' } }, { children: "Add Pin Code Details" }), void 0) }, void 0) }), void 0), _jsx(DataGrid, { getRowId: (row) => row.uid, rows: data, columns: columns, initialState: {
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }, hideFooterPagination: true, checkboxSelection: false, isRowSelectable: () => false }, void 0), _jsx(CustomPagination, { page: paging === null || paging === void 0 ? void 0 : paging.page, hasNext: (paging === null || paging === void 0 ? void 0 : paging.count) > Number(paging === null || paging === void 0 ? void 0 : paging.page_size), onPageChange: handlePageChange }, void 0)] }), void 0) : null }, void 0));
};
export const DeliveryPinCodeDetails = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
