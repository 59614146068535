import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactSlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import { Col } from "../../../components/Column";
import { Field, Form } from "../../../components/Form";
import { LabelledDiscountField } from "../../../components/Form/labelled-field";
import { Row } from "../../../components/Row";
import { Select } from "../../../components/Select";
import { SagaProvider } from "../../../saga/saga-context";
import { useSaga } from '../../../saga/use-saga';
import { StoreListGeo } from "./assign-stores-geo";
import container from "./style";
import { useSubmit } from "../../../saga/use-submit";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { Grid, TextField } from "@mui/material";
import { CategoryTreeView } from "../edit-discount-store/category-tree-view";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
export const DiscountAddToStoresInternal = (props) => {
    const type = new URLSearchParams(props.location.search).get("type");
    const orgType = type == "org";
    const [isOpen, setIsOpen] = useState(false);
    const { submit, data, error, status } = useSubmit();
    const history = useHistory();
    useEffect(() => {
        if (status === 'ok') {
            toast.success("Discount added successfully");
            history.replace('/dashboard/discount/list');
        }
    }, [status]);
    useEffect(() => {
        if (error)
            toast.error(error.message);
    }, [error]);
    const [applicable_to, setCategoryValue] = useState();
    const [selectedStores, setSelectedStores] = useState([]);
    const [discType, setDiscType] = useState();
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const [expiryDate, setExpiryDate] = useState(dayjs());
    const [effectiveDate, setEffectiveDate] = useState(dayjs());
    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm');
    };
    const isDatePassed = (date) => {
        return dayjs().isAfter(date);
    };
    const submitHandler = (formData) => {
        const { add_discount } = formData;
        const { discount_code, discount_name, discount_description, discount_value } = add_discount || {};
        if (!discount_code || discount_code == "")
            toast.error("Please specify a discount code");
        else if (!discount_name || discount_name == "")
            toast.error("Please specify a discount name");
        else if (!discount_description || discount_description == "")
            toast.error("Please specify a discount description");
        else if (!discount_type || discount_type == "" || !["fixed_amount", "percentage", "fixed-amount"].includes(discount_type.toLocaleLowerCase()))
            toast.error("Please specify a discount type");
        else if (!discount_value || discount_value == "")
            toast.error("Please specify a discount value");
        else if (discount_type.toLowerCase() == "percentage" && discount_value >= 100) {
            toast.error("Please specify a valid discount value");
        }
        else if (!product_category_uid)
            toast.error("Please choose a category");
        else if (!expiryDate) {
            toast.error("Please choose a Expiry Date");
        }
        else if (isDatePassed(expiryDate)) {
            toast.error("Please choose a Valid Expiry Date");
        }
        else if (!effectiveDate) {
            toast.error("Please choose a Expiry Date");
        }
        else if (isDatePassed(effectiveDate)) {
            toast.error("Please choose a Valid Effective Date");
        }
        else if (expiryDate.isBefore(effectiveDate)) {
            toast.error('Expiry date is before effective date');
        }
        else if (expiryDate.isSame(effectiveDate)) {
            toast.error('Expiry date  is the same as effective date');
        }
        else {
            const body = Object.assign(Object.assign({}, formData.add_discount), { applicable_to: { product_category_uid: applicable_to }, discount_type });
            // alert(JSON.stringify(body))
            if (orgType) {
                submit("/trendutube/v1/admin-offers-management/organization/discounts/add", { body: Object.assign(Object.assign({}, body), { expiry_date: formatDate(expiryDate), effective_date: formatDate(effectiveDate) }) });
            }
            else {
                if (selectedStores.length <= 0)
                    toast.error("Please select atleast one store");
                const store_uids = selectedStores.map(s => s.uid);
                submit("/trendutube/v1/admin-offers-management/stores/discounts/add", { body: Object.assign(Object.assign({}, body), { expiry_date: formatDate(expiryDate), effective_date: formatDate(effectiveDate), store_uids }) });
            }
        }
    };
    const [productCategory, setProductCategory] = useState();
    const { data: classData } = useSaga("/trendutube/v1/public/categories/list", {}, false);
    useEffect(() => {
        if (classData) {
            const { categories } = classData;
            setProductCategory(categories);
        }
    }, [classData]);
    const dis_val = [{ value: "fixed-amount", text: "Fixed Amount" }, { value: "percentage", text: "Percentage" }];
    const [discount_type, setSelectValue] = useState("percentage");
    const [productCategories, setProductCategories] = useState();
    const [product_category_uid, setCategoryUid] = useState();
    const { data: categoryData } = useSaga("/trendutube/v1/public/categories/list", {}, false);
    useEffect(() => {
        if (categoryData) {
            const { categories } = categoryData;
            const cat = categories === null || categories === void 0 ? void 0 : categories.map((item, key) => {
                return { text: item.category_name, value: item.uid };
            });
            setProductCategories(categories);
        }
    }, [categoryData]);
    const handleCategory = (categoryUid) => {
        setCategoryUid(categoryUid);
        setCategoryValue(categoryUid);
    };
    const [selectedCategoryUid, setSelectedCategoryUid] = useState(null);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [isCategoryTreeOpen, setIsCategoryTreeOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        if (product_category_uid) {
            if (productCategories) {
                const selectedCategory = findCategoryByUid(productCategories, product_category_uid);
                setSelectedCategoryName((selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.category_name) || null);
            }
        }
        else {
            setSelectedCategoryName(null);
        }
    }, [product_category_uid, productCategories]);
    const findCategoryByUid = (categories, uid) => {
        for (const category of categories) {
            if (category.uid === uid) {
                return category;
            }
            if (category.sub_categories) {
                const found = findCategoryByUid(category.sub_categories, uid);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };
    const handleSelectCategory = (uid) => {
        setCategoryUid(uid);
        setCategoryValue(uid);
        setIsCategoryTreeOpen(false);
    };
    const handleTextFieldClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsCategoryTreeOpen(!isCategoryTreeOpen);
    };
    return (_jsxs("div", Object.assign({ css: category }, { children: [_jsx("div", Object.assign({ style: { fontSize: '18px', fontWeight: '500' } }, { children: "Add Discount" }), void 0), _jsx(Row, { children: _jsx(Col, { children: _jsxs(Form, Object.assign({ name: "add_discount", onSubmit: submitHandler }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: [_jsx(LabelledDiscountField, { name: "discount_name", label: "Discount Name" }, void 0), _jsx(LabelledDiscountField, { name: "discount_code", label: "Discount Code" }, void 0), _jsx(LabelledDiscountField, { name: "discount_description", label: "Discount Description" }, void 0), _jsx(LabelledDiscountField, { name: "min_purchase_amount", label: "Minimum Purchase Amount" }, void 0), !orgType ? _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ css: { marginLeft: 5 } }, { children: "Apply discounts to stores:" }), void 0), _jsx("div", Object.assign({ css: { display: "flex", flexWrap: "wrap", margin: "15px 15px 5px 10px", justifyContent: "flex-start" } }, { children: selectedStores.map(store => {
                                                            return _jsx("div", Object.assign({ css: {
                                                                    background: "#F0F3F9",
                                                                    border: "1px solid #dedede",
                                                                    borderRadius: 20,
                                                                    marginRight: "8px",
                                                                    marginBottom: "8px",
                                                                    padding: "5px 10px",
                                                                } }, { children: _jsx("span", { children: store.store_name }, void 0) }), void 0);
                                                        }) }), void 0), _jsx("button", Object.assign({ type: "button", style: { backgroundColor: '#045998', color: 'white', padding: '10px 5px', borderRadius: '3px', border: 'none' }, onClick: () => { setIsOpen(true); } }, { children: "Choose Stores" }), void 0)] }, void 0) : null] }), void 0), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: [_jsxs("div", Object.assign({ css: { marginRight: "10px" } }, { children: [_jsx("div", Object.assign({ style: { color: '#000000', fontSize: '13px', marginTop: '1rem' } }, { children: "Discount Type" }), void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "discount_type" }, { children: _jsx(Select, { selecttext: "Please select a discount type", title: "discount_type", items: dis_val, onSelectItem: setSelectValue, keyval: "" }, void 0) }), void 0) }), void 0) }, void 0)] }), void 0), _jsx(LabelledDiscountField, { name: "min_purchase_items_count", label: "Minimum Purchase Item" }, void 0), _jsx(LabelledDiscountField, { name: "max_discount_amount", label: "Maximum Discount Amount" }, void 0), _jsx(LabelledDiscountField, { name: "max_apply_count_per_person", label: "Maximum Apply Count Per Person" }, void 0)] }), void 0), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: [_jsx(LabelledDiscountField, { name: "discount_value", label: discount_type ? discount_type.toLocaleLowerCase() == "percentage" ? "Percentage of Discount " : "Fixed(flat) Discount Amount" : "Value" }, void 0), _jsxs("div", Object.assign({ css: { marginRight: "10px", marginTop: "5px" } }, { children: [_jsx("div", Object.assign({ style: { marginBottom: '1rem', color: '#000000', fontSize: '13px', marginTop: '1rem' } }, { children: "Applicable To Categories" }), void 0), _jsx("div", { children: productCategories ?
                                                            _jsxs(_Fragment, { children: [_jsx(TextField, { value: selectedCategoryName || "Choose Category", onClick: handleTextFieldClick, fullWidth: true, InputProps: {
                                                                            endAdornment: (isCategoryTreeOpen ? _jsx(ExpandLess, { sx: { color: 'gray' } }, void 0) : _jsx(ExpandMore, { sx: { color: 'gray' } }, void 0)),
                                                                            readOnly: true,
                                                                        }, sx: {
                                                                            backgroundColor: '#F6F8F9', border: 'none', '& .MuiOutlinedInput-root': {
                                                                                '& fieldset': {
                                                                                    border: 'none', // Removes the border
                                                                                },
                                                                            },
                                                                        } }, void 0), isCategoryTreeOpen && (_jsx("div", Object.assign({ style: { position: 'relative', top: '100%', zIndex: 9999 } }, { children: _jsx(CategoryTreeView, { categories: productCategories, onSelectCategory: handleSelectCategory, selectedCategoryUid: product_category_uid || null }, void 0) }), void 0))] }, void 0) : null }, void 0)] }), void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ style: { color: '#000000', fontSize: '13px', marginTop: '1rem', marginBottom: '1rem' } }, { children: "Effective Date" }), void 0), _jsx("div", { children: _jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DemoContainer, Object.assign({ components: ['DateTimePicker', 'DateTimePicker'], sx: { backgroundColor: '#F6F8F9' } }, { children: _jsx(DateTimePicker, { value: effectiveDate, onChange: (newValue) => setEffectiveDate(newValue), ampm: false, slotProps: {
                                                                        textField: {
                                                                            InputProps: {
                                                                                sx: {
                                                                                    border: 'none',
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none', // Ensures the outline is hidden
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    } }, void 0) }), void 0) }), void 0) }, void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ style: { marginBottom: '1rem', color: '#000000', fontSize: '13px', marginTop: '1rem' } }, { children: "Expiry Date" }), void 0), _jsx("div", { children: _jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DemoContainer, Object.assign({ components: ['DateTimePicker', 'DateTimePicker'], sx: { backgroundColor: '#F6F8F9' } }, { children: _jsx(DateTimePicker, { value: expiryDate, onChange: (newValue) => setExpiryDate(newValue), ampm: false, slotProps: {
                                                                        textField: {
                                                                            InputProps: {
                                                                                sx: {
                                                                                    border: 'none',
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none', // Ensures the outline is hidden
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    } }, void 0) }), void 0) }), void 0) }, void 0)] }, void 0)] }), void 0)] }), void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: { marginRight: "10px", marginTop: "5px" } }, { children: _jsx("input", { type: "submit", value: "Create Discount", style: { backgroundColor: '#045998', color: 'white', padding: '10px 5px', borderRadius: '3px', border: 'none' } }, void 0) }), void 0) }, void 0)] }), void 0) }, void 0) }, void 0), isOpen ? _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Assign Multiple Stores to Discount", subtitle: "pick one or more stores", width: "78%", onRequestClose: () => {
                    // triggered on "<" on left top click or on outside click
                    setIsOpen(false);
                } }, { children: _jsx(StoreListGeo, { lngLat: [76.85070627053096, 9.122565669098792], onSelect: (v) => {
                        setSelectedStores(v);
                        setIsOpen(false);
                    }, currentSelection: selectedStores }, void 0) }), void 0) : null] }), void 0));
};
export const DiscountAddToStores = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(DiscountAddToStoresInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
